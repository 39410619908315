import { Schema } from 'js-data'
import Mapper from '../mapper.js'
import store from '@/api'

const CourseCategorySchema = new Schema({
  title: 'CourseCategorytitle',
  description: 'Schema for CourseCategory Records.', // optional
  type: 'object',
  properties: {
    title: {
      type: 'string',
    },
    icon: {
      type: ['string', 'null'],
    },
    backgroundImageId: {
      type: ['string', 'null'],
    },
  },
  additionalProperties: true,
  required: ['title'],
})

export const CourseCategory = store.defineMapper('CourseCategory', {
  mapperClass: Mapper,
  schema: CourseCategorySchema,
  endpoint: 'course-categories',
  relations: {
    hasMany: {
      CourseBlock: {
        localField: 'blocks',
        foreignKey: 'categoryId',
      },
    },
  },
})
