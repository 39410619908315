import { isBoolean, kebabCase } from 'lodash'
import utils from '@/helpers/utils'
import api from '@/api'
import { trailingSlashIt } from '@/helpers/slash.js'

export default {
  computed: {
    CAN_USE_INTERNAL_LOGIN () {
      return this.$store.getters['context/get']('settings.useInternalAuthentification') ?? true
    },
    CAN_CONTACT_TEACHER () {
      return this.CAN_USE_INTERNAL_LOGIN && (this.$store.getters['context/get']('settings.allowContactTeacher') ?? true)
    },
    getContextProperty (path) {
      return this.$store.getters['context/get']
    },
    USE_REGISTER_BY_INVITATION () {
      return this.getContextProperty('settings.allowAccessToInvitationCodeFeature') || false
    },
    contextExternalSignUpWebSiteLink () {
      return this.getContextProperty('metas.externalSignUpWebSiteLink')
    },
    contextExternalDemonstrationVideoLink () {
      return this.getContextProperty('metas.externalDemonstrationVideoLink')
    },
    contextSso () {
      if (this.getContextProperty('settings.oidc.enabled')) {
        return `https://${this.contextApi}/contexts/${this.contextId}/oidc/login`
      }
      return null
    },
    contextUseIllustration () {
      return this.getContextProperty('metas.boRelanceUseIllustration') !== false
    },
    contextId () {
      return this.getContextProperty('id')
    },
    contextName () {
      return this.getContextProperty('name')
    },
    contextAlias () {
      return kebabCase(this.getContextProperty('alias'))
    },
    contextApi () {
      return this.getContextProperty('hosts.api')
    },
    contextFrontoffice () {
      return this.getContextProperty('hosts.frontoffice')
    },
    contextCertification () {
      return trailingSlashIt(this.getContextProperty('hosts.certification'))
    },
    contextBackoffice () {
      return this.getContextProperty('hosts.backoffice')
    },
    contextFavicon () {
      const id = this.getContextProperty('assets.foFaviconId')
      const imgUrl = utils.getFilePath(id)
      return id ? imgUrl : false
    },
    contextLogo () {
      const id = this.getContextProperty('assets.foLogoId')
      const imgUrl = utils.getFilePath(id)
      return id ? imgUrl : false
    },
    contextLogoBadge () {
      const id = this.getContextProperty('assets.foLogoBadgeId')
      const imgUrl = utils.getFilePath(id)
      return id ? imgUrl : false
    },
    contextPrimaryColor () {
      return this.getContextProperty('metas.foPrimaryColor')
    },
    contextPrimaryVariantColor () {
      return this.getContextProperty('metas.foPrimaryVariantColor')
    },
    contextSecondaryColor () {
      return this.getContextProperty('metas.foSecondaryColor')
    },
    contextSecondaryVariantColor () {
      return this.getContextProperty('metas.foSecondaryVariantColor')
    },
    contextIconColor () {
      return this.getContextProperty('metas.foIconColor')
    },
    contextTertiaryColor () {
      return this.getContextProperty('metas.foTertiaryColor')
    },
    contextUseBorderedStyle () {
      return this.getContextProperty('metas.foStyleUseBorder') || false
    },
    contextLevelLabel () {
      return this.getContextProperty('metas.levelLabel')
    },
    contextAllowUserToSetFirstname () {
      const value = this.getContextProperty('settings.allowUserToSetFirstname')
      if (isBoolean(value)) {
        return value
      }
      return true
    },
    contextAllowAccessToCertificationFeature () {
      return this.getContextProperty('settings.allowAccessToCertificationFeature') || false
    },
    contextAllowUserToSetLastname () {
      const value = this.getContextProperty('settings.allowUserToSetLastname')
      if (isBoolean(value)) {
        return value
      }
      return true
    },
    contextSimplePasswordForInvitationCode () {
      return this.getContextProperty('settings.simplePasswordForInvitationCode') || false
    },
    contextLrsHost () {
      return this.getContextProperty('hosts.lrs')
    },
    contextLrsBasicAuth () {
      return this.getContextProperty('hosts.lrsBasicAuth')
    },
    contextAllowUserToSetMail () {
      const value = this.getContextProperty('settings.allowUserToSetMail')
      if (isBoolean(value)) {
        return value
      }
      return true
    },
    contextAllowUserToChangeUsername () {
      const value = this.getContextProperty('settings.allowUserToChangeUsername')
      if (isBoolean(value)) {
        return value
      }
      return true
    },
    contextAllowUserToSetBirthday () {
      const value = this.getContextProperty('settings.allowUserToSetBirthday')
      if (isBoolean(value)) {
        return value
      }
      return true
    },
    contextSplashScreensDefinitions () {
      return api.getMapperByName('Context').splashScreensSizes
    },
    contextTouchIconsDefinitions () {
      return api.getMapperByName('Context').touchIcons
    },
    contextSplashScreens () {
      const res = {}
      for (const size of this.contextSplashScreensDefinitions) {
        if (this.getContextProperty(`assets.splashScreenId${size}`) == null) {
          continue
        }
        res[`splashScreenId${size}`] = utils.getFilePath(this.getContextProperty(`assets.splashScreenId${size}`))
      }
      return res
    },
    contextTouchIcons () {
      const res = {}
      for (const size of this.contextTouchIconsDefinitions) {
        if (this.getContextProperty(`assets.touchIconId${size}`) == null) {
          continue
        }
        res[`touchIconId${size}`] = utils.getFilePath(this.getContextProperty(`assets.touchIconId${size}`))
      }
      return res
    },
    contextPwa () {
      return this.$store.getters['context/getManifestJson']
    },
    contextGenericCompetenciesReportingLevel () {
      return this.$store.getters['context/get']('competencies.genericCompetencies.reportingLevel') || 1
    },
  },
}
