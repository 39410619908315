
const state = {
  url: null,
}

// getters
const getters = {
}

// actions
const actions = {
}

// mutations
const mutations = {
  VIDEODIALOG_SET_VIDEO (state, url) {
    state.url = url
  },
  VIDEODIALOG_HIDE (state) {
    state.url = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
