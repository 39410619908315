import { map, orderBy } from 'lodash'
import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import Mapper from '../mapper.js'
import api from '@/api'
import moment from 'moment'

const ClassSchema = new Schema({
  title: 'ClassSchemaTitle',
  description: 'Schema for Class Records.',
  type: 'object',
  properties: {
  },
  additionalProperties: true,
})
class ClassRecord extends Record {
  get isUnlocked () {
    return this.lesson.some(lesson => lesson.state !== 'HIDDEN')
  }

  getClassPrograms () {
    return orderBy(this.classPrograms, 'order')
  }

  getPrograms () {
    return map(orderBy(this.classPrograms, 'order'), 'program')
  }

  async loadTeachers () {
    const params = {
      role: 'TEACHER',
    }

    const response = await this.constructor.mapper.relatedUser(this.id, { params })
    const datas = response?.data || []

    return datas.map((user) => api.add('AppUser', user))
  }

  autorefresh (ms) {
    if (this._cron) {
      window.clearTimeout(this._cron)
    }
    if (ms > 0) {
      console.log(`AUTO_REFRESH CLASS in ${ms}ms`)
      this._cron = window.setTimeout(() => {
        console.log('REFRESH')
        api.find('Class', this.id, { force: true })
      }, ms + 200)
    }
  }
}

const Klass = api.defineMapper('Class', {
  mapperClass: Mapper,
  ClassSchema,
  endpoint: 'classes',
  relations: {
    hasMany: {
      ClassUser: {
        foreignKey: 'classId',
        localField: 'classUsers',
      },
      Lesson: {
        foreignKey: 'classId',
        localField: 'lesson',
      },
      ClassProgram: {
        localField: 'classPrograms',
        foreignKey: 'classId',
      },
    },
  },
  recordClass: ClassRecord,
})

api.on('add', (mapperName, record) => {
  record = [].concat(record)[0]
  if (mapperName === 'Class' && record && record.startAt) {
    const cron = moment(record.startAt) - moment()
    if (cron > 0) {
      record.autorefresh(cron)
    }
  }
})

addActions({
  relatedUser: {
    pathname: 'users/data',
    method: 'GET',
  },
})(Klass)

export default Klass
