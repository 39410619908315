import { forOwn, isEmpty } from 'lodash'
const state = () => ({
  to: null,
  files: null,
  hide: false,
})

// getters
const getters = {
  contactIsVisible (state) {
    return state.to || !isEmpty(state.files)
  },
  contactIsAutoClosed (state) {
    return state.hide
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  CONTACTFORM_AUTOHIDE (state) {
    state.hide = true
    setTimeout(() => {
      state.hide = false
    }, 100)
  },
  CONTACTFORM_SET_FILE (state, files) {
    state.files = files
  },
  CONTACTFORM_SET_TO (state, to) {
    state.to = to
  },
  CONTACTFORM_HIDE (state) {
    state.to = null
    state.files = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
