<template lang="pug">
  doctype html
  .pi-contact-user
    el-dialog.secondary(v-if='isVisible', :visible.sync="isVisible", :title="title")
      el-form(:ref="formName", :model="form", :rules="rules")
        el-form-item(label="Objet")
          el-input(v-model="object", disabled)
        el-form-item(label="Message", prop='message')
          el-input(
            :placeholder="$t('actions.write.something', {something: placeholders.message} )",
            type='textarea',
            v-model="form.message",
            :autosize='{minRows: 3}')
        p
          em Vos coordonnées seront transmises à la personne contactée
        el-button.btn__square.bg-primary(@click="submit", :loading="isSending") Envoyer à {{ recipient.displayName }}
          i.el-icon-pi-fleche-bouton

        .alerts.space-before(v-for="(alert, index) in alerts")
          pi-alert.little-margin-top(:title='alert.title', :type="alert.type", show-icon, @close="alerts.splice(index, 1)")
</template>

<script>
import store from '@/api'
import { get, isNull } from 'lodash'
import PiAlert from '@/components/Ui/PiAlert.vue'

export default {
  name: 'pi-contact-user',
  components: { PiAlert },
  filters: {
  },
  data () {
    const placeholders = {
      message: 'votre message',
    }
    return {
      formName: 'contactUser',
      placeholders,
      rules: {
        message: {
          required: true,
          message: this.$t('actions.write.something', { something: placeholders.message }),
          trigger: 'blur',
        },
      },
      form: {
        object: '',
        message: '',
      },
      isSending: false,
      alerts: [],
    }
  },
  computed: {
    title () {
      return `Contacter ${this.recipient.displayName}`
    },
    to () {
      return this.$store.state.contact.to
    },
    isVisible: {
      get () {
        return !isNull(this.to)
      },
      set (value) {
        if (!value) {
          this.$store.commit('CONTACTFORM_HIDE')
        }
      },
    },
    recipient () {
      this.$store.getters.DSAppUser[this.to]
      return store.get('AppUser', this.to) || {}
    },
    object () {
      return `"${this.currentUserRecord.firstname} ${this.currentUserRecord.lastname}" vous a envoyé un message depuis ${this.contextName}`
    },
  },
  watch: {
    isVisible (newValue, oldValue) {
      if (!newValue) {
        this.$set(this, 'alerts', [])
        this.form.message = ''
      }
    },
  },
  created () {
    window._cu = this
  },
  methods: {
    submit () {
      this.$set(this, 'alerts', [])
      this.$refs[this.formName].validate(valid => {
        if (valid) {
          this.isSending = true
          this.recipient.contact(this.form.message).then(response => {
            this.alerts.push({
              title: 'Message envoyé',
              type: 'success',
            })
            setTimeout(() => {
              this.isVisible = false
              this.contactAutoHide()
            }, 1500)
          }).catch(err => {
            const errorCode = get(err, 'response.data.code', 'UNDEFINED')
            this.alerts.push({
              title: this.$t(`errors.${errorCode}.title`),
              type: 'error',
            })
          }).then(() => {
            this.isSending = false
          })
        } else {
          this.alerts.push({
            title: 'Des données sont manquantes pour satisfaire votre requète',
            type: 'error',
          })
        }
      })
    },
  },
  asyncComputed: {
  },
}

</script>
