import Vue from 'vue'
import VueMq from 'vue-mq'
import {
  convertBreakpointsToMediaQueries,
  subscribeToMediaQuery,
} from 'vue-mq/src/helpers'
import breakpoints from './breakpoints.js'
const mq = {
  current: 'sm',
}

const mediaQueries = convertBreakpointsToMediaQueries(breakpoints)

Object.entries(mediaQueries).forEach(([currentMq, mediaQuery]) => {
  const enter = () => {
    mq.current = currentMq
  }
  subscribeToMediaQuery(mediaQuery, enter)
})

Vue.use(VueMq, {
  breakpoints,
  defaultBreakpoint: 'sm',
})

export default mq
