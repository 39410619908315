if (!document.elementsFromPoint) {
  document.elementsFromPoint = elementsFromPoint
}

function elementsFromPoint (x, y) {
  const parents = []
  let parent
  do {
    if (parent !== document.elementFromPoint(x, y)) {
      parent = document.elementFromPoint(x, y)
      parents.push(parent)
      parent.style.pointerEvents = 'none'
    } else {
      parent = false
    }
  } while (parent)
  parents.forEach(function (parent) {
    parent.style.pointerEvents = 'all'
    return parent.style.pointerEvents
  })
  return parents
}
