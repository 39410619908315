import mojs from '@mojs/core'
const drawSignal = (el, binding, vnode, oldVnode) => {
  const speed = (binding.value && binding.value.speed) || 3000
  const parent = el.querySelector('.mojs-container')
  window.clearInterval(el.signalTimer)
  if (parent && binding.value && binding.value.options) {
    if (!el.signal) {
      el.signal = new mojs.Burst({
        ...binding.value.options,
        parent,
      })
    } else {
      el.signal.tune(
        binding.value.options,
      )
    }
    el.signal.replay()
    el.signalTimer = window.setInterval(() => {
      el.signal.replay()
    }, speed)
  }
}

export default {
  // définition de la directive
  inserted: function (el, binding, vnode, oldVnode) {
    const div = window.document.createElement('div')
      .classList.add('mojs-container')
    el.appendChild(div)
    drawSignal(el, binding, vnode, oldVnode)
  },
  unbind: function (el, binding, vnode, oldVnode) {
    window.clearInterval(el.signalTimer)
  },
  update: function (el, binding, vnode, oldVnode) {
    drawSignal(el, binding, vnode, oldVnode)
  },
}
