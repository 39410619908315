import store from '@/api'
import moment from 'moment'
import { compact, flatten, forOwn, get, isEmpty, isString, map, uniq } from 'lodash'
import promiseMap from 'promise-map'
import $mq from '@/libs/mq/index.js'

const state = () => ({
  LESSONSVISIBLE: {
    ids: [],
    autorefresh: 20 * 1000, // toutes les 20s
    interval: null, // le setInterval
  },
  DASHBOARD: {
    tree: [],
    classes: [],
    lessons: [],
    search: {
      past: null,
      next: null,
      competencyId: null,
      programId: null,
    },
  },
  CLASSVISIBLE: {
    autorefresh: 20 * 1000, // toutes les 20s
    interval: null, // le setInterval
  },
})

// getters
const getters = {
  LESSONSVISIBLE_ids (state) {
    return state.LESSONSVISIBLE.ids
  },
  DASHBOARD_all_classes (state) {
    return state.DASHBOARD.classes
  },
  DASHBOARD_classes (state) {
    let records = state.DASHBOARD.classes
      .map(classe => store.get('Class', classe.id))
      .filter(Boolean)

    if (state.DASHBOARD.search.next) {
      records = records.filter(c => moment(c.startAt) > moment())
    } else {
      records = records.filter(c => moment(c.startAt).add(-1, 'day') < moment())
    }
    if (state.DASHBOARD.search.past) {
      records = records.filter(c => moment(c.expireAt) < moment())
    }

    if (state.DASHBOARD.search.competencyId) {
      records = records.filter(c =>
        c.classPrograms.some(cp => get(cp, 'program.metas.tags.courseCompetencyIds', [])
          .includes(state.DASHBOARD.search.competencyId)),
      )
    }

    if (state.DASHBOARD.search.programId) {
      records = records.filter(c =>
        c.classPrograms.some(cp => get(cp, 'programId').includes(state.DASHBOARD.search.programId)),
      )
    }
    return records
  },
  SEARCH_isDefined (state) {
    const { search } = state.DASHBOARD
    return Object.values(search).some(Boolean)
  },
  SEARCH_get (state, getters) {
    return (prop) => state.DASHBOARD.search[prop]
  },
  SEARCH_programIds (state) {
    const records = state.DASHBOARD.classes.map(classe => store.get('Class', classe.id))
    return uniq(records.map(classe => classe.classPrograms.map(cp => cp.program?.id)).flat().filter(Boolean))
  },
  SEARCH_programs (state, getters) {
    return store.filter('Program', {
      _id: {
        in: getters.SEARCH_programIds,
      },
      orderBy: 'name',
    })
  },
  SEARCH_competences (state, getters) {
    const competencyIds = uniq(compact(flatten(map(getters.SEARCH_programs, 'metas.tags.courseCompetencyIds'))))
    return store.filter('CourseCompetency', {
      _id: {
        in: competencyIds,
      },
      orderBy: 'title',
    })
  },
}

// actions
const actions = {
  updateLessonsVisible ({ dispatch, commit, rootGetters }, lessonIds) {
    commit('SET_LESSONSVISIBLE', lessonIds)
  },
  loadTree ({ dispatch, commit, rootGetters }) {
    return dispatch('loadClasses').then(() => ['loadTeachers', 'loadLessons'])
      .then(promiseMap(action => dispatch(action)))
  },
  loadClasses ({ dispatch, commit, rootGetters }) {
    const uid = rootGetters.getCurrentUser.id
    if (!uid) {
      return
    }
    const whereClass = {
      'settings.mobile.allowed': get({ xs: true }, $mq.current, undefined),
      contextId: rootGetters['context/get']('id'),
      expired: false,
    }
    return store.findAll('ClassUser',
      {
        filter: {
          userId: uid,
          role: 'STUDENT',
        },
        options: {
          sort: '-createdAt',
        },
        populate: {
          path: 'class',
          match: whereClass,
          populate: [
            {
              path: 'classUsers',
              match: { role: 'TEACHER' },
            },
            {
              path: 'classPrograms',
              populate: {
                path: 'program',
                populate: {
                  path: 'programCourses',
                  populate: { path: 'course' },
                },
              },
            },
          ],
        },
      }, { force: true, raw: true }).then(response => {
      const classes = []
      response.data.forEach(classUser => {
        if (classUser.class) classes.push(classUser.class)
      })
      commit('DASHBOARD_SET_CLASSES', classes)
    }).catch(console.error)
  },
  loadTeachers ({ dispatch, commit, rootGetters, getters }, params = { classId: false }) {
    const { classId } = params
    if (!classId && !isString(classId)) {
      console.error('[ DASHBOARD - TEACHERS ] classId required')
      return []
    }
    return store.findAll('ClassUser', {
      classId,
      filter: {
        role: 'TEACHER',
      },
      populate: { path: 'user' },
    }, { force: true, raw: true }).then(response => {
      return map(response.data, 'user')
    }).catch(error => {
      console.error('[ DASHBOARD - TEACHERS ] erreur lors du chargement des TEACHERS')
      console.error(error)
      return []
    })
  },
  loadLesson ({ dispatch, commit, rootGetters, getters }, params = { lessonId: false, deep: true }) {
    const { lessonId, deep } = params
    let lessonIds = []
    if (lessonId) {
      lessonIds = [].concat(lessonId)
    }
    let populate = {}
    if (deep === true) {
      populate = { path: 'pages', match: { state: { $ne: 'ARCHIVED' } } }
    }
    return store.findAll('Lesson', {
      filter: {
        contextId: rootGetters['context/get']('id'),
        userId: rootGetters.getCurrentUser.id,
        _id: {
          $in: lessonIds,
        },
      },
      populate,
    }, { force: true, raw: true }).then(response => {
      return get(response, 'data[0]')
    })
  },
  loadLessons ({ dispatch, commit, rootGetters, getters }, params = { classId: false, programCourseIds: '', deep: true }) {
    const { classId, programCourseIds: pCIds, deep } = params
    let programCourseIds = []
    let classIds = []

    if (pCIds) {
      programCourseIds = [].concat(pCIds)
    }

    if (classId) {
      classIds = [].concat(classId)
    } else {
      classIds = map(getters.DASHBOARD_all_classes, 'id')
    }

    return store.findAll('Lesson', {
      filter: {
        contextId: rootGetters['context/get']('id'),
        userId: rootGetters.getCurrentUser.id,
        classId: {
          $in: classIds,
        },
        programCourseId: {
          $in: programCourseIds,
        },
      },
      ...(deep === true ? { populate: { path: 'pages', match: { state: { $ne: 'ARCHIVED' } } } } : {}),
    }, { force: true, raw: true }).then(response => {
      return response.data
    })
  },
  updateClassVisible ({ dispatch, commit, rootGetters, getters, state }) {
    commit('STOP_CLASS_REFRESH')
    commit('SET_CLASS_REFRESH', () => {
      console.log('AUTO_REFRESH CLASSES')
      dispatch('loadClasses')
    })
  },
}

// mutations
const mutations = {
  SEARCH_set (state, { property, value }) {
    state.DASHBOARD.search[property] = value
  },
  SEARCH_delete (state, property) {
    state.DASHBOARD.search[property] = null
  },
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  SET_CLASS_REFRESH (state, intervalCallback) {
    state.CLASSVISIBLE.interval = window.setInterval(intervalCallback, state.CLASSVISIBLE.autorefresh)
  },
  STOP_CLASS_REFRESH (state, ids) {
    window.clearInterval(state.CLASSVISIBLE.interval)
  },
  STOP_LESSON_REFRESH (state, ids) {
    window.clearInterval(state.LESSONSVISIBLE.interval)
  },
  SET_LESSONSVISIBLE (state, ids = null) {
    if (ids !== null) {
      state.LESSONSVISIBLE.ids = ids
    }
    window.clearInterval(state.LESSONSVISIBLE.interval)
    if (!isEmpty(state.LESSONSVISIBLE.ids)) {
      state.LESSONSVISIBLE.interval = window.setInterval(() => {
        if (isEmpty(state.LESSONSVISIBLE.ids)) {
          window.clearInterval(state.LESSONSVISIBLE.interval)
        } else {
          store.findAll('Lesson', {
            filter: {
              userId: window.localStorage.getItem('id_user'),
              _id: { $in: state.LESSONSVISIBLE.ids },
            },
          }, { force: true }).then(() => {
            console.log(`AUTO_REFRESH LESSONS x${state.LESSONSVISIBLE.ids.length}`)
          })
        }
      }, state.LESSONSVISIBLE.autorefresh)
    }
  },
  DASHBOARD_SET_TREE (state) {
    state.DASHBOARD.tree = [Math.random()]
  },
  DASHBOARD_SET_CLASSES (state, classes) {
    state.DASHBOARD.classes = classes
  },
  DASHBOARD_SET_LESSONS (state, lessons) {
    state.DASHBOARD.lessons = lessons
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
