/* eslint-disable no-unneeded-ternary */

import store from '@/api/index.js'
import { find, get, isNumber, map } from 'lodash'

export default {
  state: {
    currentLessonId: false,
    currentPageId: false,
    currentNodeId: false,
    timeSpent: 0,
    timefrom: 0,
    pinger: null,
    timerUpdater: null,
    blockSectionName: '',
  },
  mutations: {
    SET_BLOCK_SECTION_NAME (state, blockSectionName) {
      state.blockSectionName = blockSectionName
    },
    SET_COURSENODE_ID (state, nodeId) {
      state.currentNodeId = nodeId
      state.timeSpent = 0
    },
    SET_CURRENTLESSON_ID (state, lessonId) {
      state.currentLessonId = lessonId
    },
    SET_PAGE_ID (state, pageId) {
      state.currentPageId = pageId
    },
    INCREMENT_TIMER (state) {
      const lesson = store.get('Lesson', state.currentLessonId)
      if (!lesson) {
        return
      }
      if (lesson && !isNumber(lesson.timeSpent)) {
        lesson.timeSpent = 0
        lesson.emit('change', lesson)
      }
      lesson.timeSpent++
      state.timeSpent = lesson.timeSpent
    },
  },
  getters: {
    currentlesson: (state, getters) => {
      getters.DSLesson
      getters.DSLessonPage
      return store.get('Lesson', state.currentLessonId)
    },
    lessonTimeRaining: (state, getters) => {
      return Math.max(getters.currentlesson.timeLimit - getters.lessonTimeSpent, 0)
    },
    lessonTimeSpent: (state, getters) => {
      if (!getters.currentlesson || !getters.currentlesson.timeLimit) return 0
      return state.timeSpent
    },
    percentLessonTimeSpent: (state, getters) => {
      if (!getters.lessonTimeSpent || !getters.currentlesson.timeLimit) return 0
      return Math.min((getters.lessonTimeSpent / getters.currentlesson.timeLimit), 1)
    },
    currentPage: (state, getters) => {
      getters.DSLessonPage
      return store.get('LessonPage', state.currentPageId)
    },
    currentSectionName: (state, getters) => {
      if (!getters.currentPageId) {
        return state.blockSectionName || ''
      }
      getters.DSLessonPage
      const page = store.get('LessonPage', getters.currentPageId)
      if (!page) {
        return ''
      }
      const sortedTree = get(getters.currentlesson, 'sortedTree')
      if (sortedTree) {
        const section = find(sortedTree, section =>
          find(section.pages, { id: page.id }),
        )
        return get(section, 'title', ' ')
      }
      const node = get(this, 'page.node')
      if (page.lessonId === 'id-correction-lesson' && node) {
        return get(node, 'parent.title', '')
      }
      return ''
    },
    currentPageId: (state, getters) => {
      getters.DSLessonPage
      return state.currentPageId
    },
    currentSection: (state, getters) => {
      getters.DSCourseNode
      return get(getters.currentCourseNode, 'parent', false)
    },
    pagesCurrentPositionIndex: (state, getters) => {
      if (!getters.currentlesson || !getters.currentlesson.sortedTree || !getters.currentPage) {
        return 0
      }
      const section = find(getters.currentlesson.sortedTree, section => {
        return find(section.pages, { id: getters.currentPage.id })
      })
      const pages = map(get(section, 'pages', []), 'id')
      return pages.indexOf(getters.currentPageId)
    },
    pagesTotalOnCurrentSection: (state, getters) => {
      if (!getters.currentlesson || !getters.currentlesson.sortedTree || !getters.currentPage) {
        return 0
      }
      const section = find(getters.currentlesson.sortedTree, section => {
        return find(section.pages, { id: getters.currentPage.id })
      })
      return get(section, 'pages.length', 0)
    },
  },
  actions: {
    courseKillPingers ({ state }) {
      window.clearInterval(state.pinger)
      window.clearInterval(state.timerUpdater)
    },
    setLesson ({ dispatch, commit, state, getters }, lessonRecord) {
      if (state.currentLessonId !== lessonRecord.id && lessonRecord.id !== 'id-correction-lesson') {
        commit('SET_CURRENTLESSON_ID', lessonRecord.id)
      }
    },
    setCurrentPage ({ dispatch, commit, state, getters }, page) {
      commit('SET_PAGE_ID', page.id)
      commit('SET_COURSENODE_ID', page.nodeId)
    },
  },
}
