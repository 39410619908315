import VueRouter from 'vue-router'
import App from '@/main.js'
import {
  defaultsDeep,
  each,
  get,
  isArray,
} from 'lodash'
const PiPortal = () => import('./components/Authentification/Portal')
const PiReset = () => import('./components/Authentification/Reset')
const PiAccount = () => import('./components/Content/Account')
const PiIndex = () => import('./components/Content/Index')
const PiRenderCourseNode = () => import('./components/Render/RenderCourseNode')
const PiRenderPage = () => import('./components/Render/RenderPage')
const PiRenderPageActions = () => import('./components/Render/RenderPageActions')
const PiRenderPageQuestions = () => import('./components/Render/RenderPageQuestions')
const PiBlockPreview = () => import('./components/Preview/BlockPreview')
const PiBOPageCorrection = () => import('./components/Correction/BOPageCorrection')
const PiPageCorrection = () => import('./components/Correction/PiPageCorrection')
const EvaluateModel = () => import('./components/Evaluate/Model')
const TestBadges = () => import('./components/Test/Badges')
const Dashboard = () => import('./components/Dashboard/DashboardPage')
const UserCertificates = () => import('./components/Dashboard/tabs/UserCertificates')
const UserLevels = () => import('./components/Dashboard/tabs/UserLevels')
const UserJobs = () => import('./components/Dashboard/tabs/UserJobs')
const UserLogBook = () => import('./components/Dashboard/tabs/UserLogBook')
const UserStats = () => import('./components/Dashboard/tabs/UserStats')
const Pi404 = () => import('./components/Errors/404')
const Pi403 = () => import('./components/Errors/403')
const Mentions = () => import('./components/Legal/Mentions')

const defaultRouteSettings = {
  meta: {
    permission: 'grains|client|public',
    fail: '/login',
  },
}

const routes = [
  {
    path: '/',
    meta: {
      hasSidebar: true,
      breadcrumb: ['Mon espace', 'Mes parcours'],
    },
    component: PiIndex,
    name: 'Home',
  },
  {
    path: '/me',
    meta: {
      hasSidebar: true,
      breadcrumb: ['Mon espace', 'Mon profil'],
    },
    component: PiAccount,
    name: 'Account',
  },
  {
    path: '/login',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
      breadcrumb: ['Authentification au portail'],
    },
    component: PiPortal,
    name: 'login',
  },
  {
    path: '/reset/:token',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
      breadcrumb: ['Renouvellement de mot de passe'],
    },
    component: PiReset,
    name: 'ResetToken',
    props: true,
  },
  {
    path: '/reset/',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
      breadcrumb: ['Renouvellement de mot de passe'],
    },
    component: PiReset,
    name: 'reset',
    props: true,
  },
  // NODES
  {
    path: '/render/node/:id',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: PiRenderCourseNode,
    name: 'PiRenderCourseNode',
    props: true,
  },
  // SESSION
  {
    path: '/session/:id',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: PiRenderPage,
    name: 'PiRenderPage',
    props: true,
  },
  {
    path: '/session/:id/actions',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: PiRenderPageActions,
    name: 'PiRenderPageActions',
    props: true,
  },
  {
    path: '/session/:id/questions',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: PiRenderPageQuestions,
    name: 'PiRenderPageQuestions',
    props: true,
  },
  {
    path: '/preview',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: PiBlockPreview,
    name: 'preview',
  },
  {
    path: '/correction',
    meta: {
      hasOnlyContent: true,
      hasSidebar: false,
      hasProgression: true,
    },
    component: PiBOPageCorrection,
    name: 'correction',
  },
  {
    path: '/correction/:id',
    meta: {
      hasOnlyContent: true,
      hasSidebar: false,
      hasProgression: true,
    },
    component: PiPageCorrection,
    name: 'PiCorrectionPage',
    props: true,
  },
  // Evaluation de contenus
  {
    path: '/evaluate/:type/:id',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: EvaluateModel,
    name: 'EvaluateModel',
    props: true,
  },
  {
    path: '/404',
    meta: {
      hasOnlyContent: false,
      hasSidebar: false,
    },
    component: Pi404,
    name: '404',
  },
  {
    path: '/403',
    meta: {
      hasOnlyContent: true,
      hasSidebar: false,
    },
    component: Pi403,
    name: '403',
  },
  {
    path: '/test-badges',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
      breadcrumb: ['badges'],
    },
    component: TestBadges,
    name: 'TestBadges',
  },
  {
    path: '/tableau-de-bord',
    name: 'Dashboard',
    meta: {
      hasSidebar: true,
      hasOnlyContent: false,
      breadcrumb: ['Mon espace', 'Mon tableau de bord'],
    },
    redirect: '/tableau-de-bord/niveaux',
    component: Dashboard,
    children: [
      {
        path: 'niveaux',
        name: 'DashboardLevels',
        meta: {
          hasSidebar: true,
          hasOnlyContent: false,
          breadcrumb: ['Mon espace', 'Mon tableau de bord'],
          currentTab: 'levels',
        },
        component: UserLevels,
      },
      {
        path: 'metiers',
        name: 'DashboardJobs',
        meta: {
          hasSidebar: true,
          hasOnlyContent: false,
          breadcrumb: ['Mon espace', 'Mon tableau de bord'],
          currentTab: 'jobs',
        },
        component: UserJobs,
      },
      {
        path: 'statistiques',
        name: 'DashboardStats',
        meta: {
          hasSidebar: true,
          hasOnlyContent: false,
          breadcrumb: ['Mon espace', 'Mon tableau de bord'],
          currentTab: 'stats',
        },
        component: UserStats,
      },
      {
        path: 'validations',
        alias: [
          'certicates',
        ],
        name: 'DashboardCertificates',
        meta: {
          hasSidebar: true,
          hasOnlyContent: false,
          breadcrumb: ['Mon espace', 'Mon tableau de bord'],
          currentTab: 'certificates',
        },
        component: UserCertificates,
      }, {
        path: 'logbook',
        name: 'DashboardLogbook',
        meta: {
          hasSidebar: true,
          hasOnlyContent: false,
          breadcrumb: ['Mon espace', 'Mon tableau de bord'],
          currentTab: 'logbook',
        },
        component: UserLogBook,
      },
    ],
  },
  {
    path: '/legal/mentions',
    name: 'LegalMentions',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
    },
    props: {
      legal: 'cgu',
    },
    component: Mentions,
  },
  {
    path: '/legal/protection-des-donnees',
    name: 'LegalDataProtection',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
    },
    props: {
      legal: 'rgpd',
    },
    component: Mentions,
  },

  {
    path: '*',
    redirect: '404',
  },
]
routes.forEach(el => {
  defaultsDeep(el, defaultRouteSettings)

  if (el.children) {
    el.children.forEach(child => {
      defaultsDeep(child, defaultRouteSettings)
    })
  }
})
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (isArray(get(to, 'params.removeFromLocalstorage')) && typeof window.localStorage !== 'undefined') {
    each(to.params.removeFromLocalstorage, key => {
      window.localStorage.removeItem(key)
    })
  }
  // Génération par défault des titles, pour les pages d'édition, cf Mixins/Edit.js
  let title
  if (App && App.$store && get(to, 'name') && App.$te(`routes.${to.name}`)) {
    title = `${App.$store.getters['context/get']('name')} - ${App.$tc(`routes.${to.name}`)}`
  } else if (App && App.$store) {
    title = `${App.$store.getters['context/get']('name')} - Espace Apprenant`
  } else {
    title = 'Connecting...'
  }
  document.title = title
  next()
})
router.afterEach((to, from) => {
  if (App && App.$store) {
    if (to.name !== 'PiRenderPage') {
      App.$store.dispatch('courseKillPingers')
    }
    App.$store.commit('STOP_LESSON_REFRESH')
    App.$store.commit('STOP_CLASS_REFRESH')
  }
})

export default router
