/**
 * INFO: NotificationManager
 * display flash messages either with or without persistance and status (alert, error, info, notice, ...)
 *
 * addAlert : {
 *    text: Texte de la notification
 *    description: Description de la notification
 *    time: (moment())
 *    duration: Temps(ms) de duration (false, null, ou 0 : la notification persiste jusqu'à sa fermeture)
 *    type: 'alert', 'message', 'notification' (Définit le type de la notification),
 *    class: Classe(s) à ajouter à l'objet crée
 *  }
 */
import moment from 'moment'
import { forOwn } from 'lodash'
const state = () => ({
  alerts: [],
})

// GETTERS
const getters = {
  getAlerts: (state, commit) => {
    if (state.alerts.length > 0) {
      state.alerts.forEach((alert) => {
        // @info: Permet d'enlever toutes les Alertes périmées avec la propriété duration:x(ms)
        if (alert.death && moment().diff(alert.death) >= 0) {
          state.alerts = state.alerts.filter((el) => {
            return el.time !== alert.time
          })
        }
        // @info: Permet d'afficher une seule fois les Alertes (sauf si alert.persist = true)
        if (alert.printed && !alert.persist) {
          state.alerts = state.alerts.filter((el) => {
            return el.time !== alert.time
          })
        }
        // @info: @todo: A améliorer, solution hack pour refresh la zone de notification lors du changement de vue
        if (alert.refresh) {
          state.alerts = state.alerts.filter((el) => {
            return el.time !== alert.time
          })
        }
        alert.printed = true
      })
    }
    return state.alerts
  },
}
// ACTIONS
const actions = {
}
// MUTATIONS
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  addAlert (state, alert) {
    alert.time = moment()
    alert.printed = false
    if (!alert.onClose) alert.onClose = () => { return true }
    if (alert.duration > 0 && alert.persist) {
      alert.death = moment(moment(alert.time).add(alert.duration, 'seconds'))
    }
    state.alerts.push(alert)
  },
  addMessage (state, message) {
    message.customClass = 'message-offset'
    message.context.$message(message)
    state.alerts.push({ refresh: true, time: moment() })
  },
  addNotification (state, notification) {
    notification.customClass = 'notification-offset'
    notification.context.$notify(notification)
    state.alerts.push({ refresh: true, time: moment() })
  },
  deleteAlert (state, alert) {
    state.alerts = state.alerts.filter((el) => {
      return el.time !== alert.time
    })
  },
  removeNonPersistantAlerts (state) {
    for (let i = state.alerts.length - 1; i >= 0; i--) {
      if (state.alerts[i].persist === false) {
        state.alerts.splice(i, 1)
      }
    }
  },
  refreshAlerts (state) {
    for (let i = state.alerts.length - 1; i >= 0; i--) {
      if (state.alerts[i].duration && state.alerts[i].time + state.alerts[i].duration < moment()) {
        state.alerts.splice(i, 1)
      }
    }
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
