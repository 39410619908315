import { trailingSlashIt } from '@/helpers/slash.js'

const Configuration = {
  basePath: trailingSlashIt(window.localStorage.getItem('apiBasePath') || trailingSlashIt(process.env.VUE_APP_API_GENERIC)),
}

if (process.env.NODE_ENV !== 'production' && process.env.VUE_APP_LOCAL === 'true') {
  if (process.env.VUE_APP_X_HOST) {
    Configuration['X-Host'] = process.env.VUE_APP_X_HOST
  }

  if (process.env.VUE_APP_X_ORIGIN) {
    Configuration['X-Origin'] = process.env.VUE_APP_X_ORIGIN
  }

  if (localStorage.getItem('X-Host')) {
    Configuration['X-Host'] = localStorage.getItem('X-Host')
  }

  if (localStorage.getItem('X-Origin')) {
    Configuration['X-Origin'] = localStorage.getItem('X-Origin')
  }
}

export default Configuration
