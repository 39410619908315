import axios from 'axios'
import { untrailingSlashIt } from '@/helpers/slash.js'
import store from '@/store/store.js'
import api from '@/api'
import configuration from '@/api/configuration.js'

const headers = {}

if (process.env.NODE_ENV !== 'production' && process.env.VUE_APP_LOCAL === 'true') {
  if (configuration['X-Host']) {
    headers['X-Host'] = configuration['X-Host']
  }

  if (configuration['X-Origin']) {
    headers['X-Origin'] = configuration['X-Origin']
  }
}

const isGenericSessionStorage = (window.sessionStorage.getItem('useGenericContext') === 'true')
const request = axios.get(`${untrailingSlashIt(process.env.VUE_APP_API_GENERIC)}/contexts/current`, { headers })
  .then(response => {
    api.add('Context', response.data)
    return store.dispatch('context/setContextId', response.data.id)
  })
  .then(() => store.dispatch('context/useGenericContext', isGenericSessionStorage))
  .then(() => store.dispatch('currentUser'))

export default request
