import { Record, Schema } from 'js-data'
import store from '@/api'
import Mapper from '../mapper.js'
import { isArray } from 'lodash'

const tree = {
  // parent : child
  root: 'container',
  container: ['block', 'multiblock'],
  multiblock: 'block',
  block: 'resource',
  resource: null,
}

const CourseNodeSchema = new Schema({
  title: 'CourseNodeShemaTitle',
  description: 'Schema for CourseNode Records.', // optional
  type: 'object',
  properties: {
    blockId: {
      type: ['string', 'null'],
    },
    order: {
      type: 'integer',
    },
    parentId: {
      type: ['string', 'null'],
    },
    courseId: {
      type: ['string', 'null'],
    },
    settings: {
      type: ['object'],
      additionalProperties: true,
    },
  },
  additionalProperties: true,
})

class CourseNodeRecord extends Record {
  loadChildren (options = {}) {
    let type = tree[this.type]
    if (isArray(type)) {
      type = { $in: type }
    }
    return store.findAll('CourseNode', { filter: { type, parentId: this.id }, options: { sort: 'order' }, ...options }, { force: true })
  }

  get childrenList () {
    let type = tree[this.type]
    if (isArray(type)) {
      type = { in: type }
    }
    const newChildrenList = store.filter('CourseNode', { where: { type, parentId: this.id }, sort: 'order' })
    if (typeof this._childrenList === 'undefined' ||
      this._childrenList.length !== newChildrenList.length) {
      this._childrenList = newChildrenList
    }

    return this._childrenList
  }

  set childrenList (datas) {
    console.log('set this._childrenList : ', datas)
  }
}

export const CourseNode = store.defineMapper('CourseNode', {
  mapperClass: Mapper,
  schema: CourseNodeSchema,
  recordClass: CourseNodeRecord,
  endpoint: 'course-nodes',
  relations: {
    belongsTo: {
      CourseBlock: {
        localField: 'block',
        localKey: 'blockId',
      },
      CourseNode: {
        localField: 'parent',
        localKey: 'parentId',
      },
      Course: {
        localField: 'course',
        localKey: 'courseId',
      },
    },
    hasMany: {
      CourseNode: {
        localField: 'children',
        foreignKey: 'parentId',
      },
      LessonPage: {
        foreignKey: 'nodeId',
        localField: 'pages',
      },
    },
  },
})
