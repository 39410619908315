import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import store from '@/api'

export const INTERNAL_GENERIC_CONTEXT_ID = 'is_generic'

const splashScreensSizes = ['640x1136', '750x1334', '1242x2208', '1125x2436', '828x1792', '1242x2688', '1536x2048', '1668x2224', '1668x2388', '2048x2732']
const touchIcons = ['192x192', '512x512']

const ContextSchema = new Schema({
  title: 'Context',
  description: 'Schema for Contexts.',
  type: 'object',
  properties: {
    alias: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    hosts: {
      type: 'object',
      additionalProperties: true,
      properties: {
        api: {
          type: 'string',
        },
        backoffice: {
          type: 'string',
        },
        certification: {
          type: 'string',
        },
        frontoffice: {
          type: 'string',
        },
      },
    },
    assets: {
      type: 'object',
      additionalProperties: true,
    },
    metas: {
      type: 'object',
      additionalProperties: true,
    },
    settings: {
      type: 'object',
      additionalProperties: true,
    },
  },
  additionalProperties: true,
  required: ['alias', 'name'],
})

class ContextRecord extends Record {
}
export const Context = store.defineMapper('Context', {
  schema: ContextSchema,
  endpoint: 'contexts',
  paths: {
    list: 'ContextList',
    edit: 'ContextEdit',
  },
  relations: {
  },
  recordClass: ContextRecord,
  // pattern,
  splashScreensSizes,
  touchIcons,
})

addActions({
})(Context)
