import '@/libs/polyfill/elementsFromPoint.js'
import '@/libs/polyfill/arrayFind.js'
import '@/libs/polyfill/promiseFinally.js'
import '@/registerServiceWorker'

import Vue from 'vue'

import ElementUi from 'element-ui'
import AsyncComputed from 'vue-async-computed'

import router from '@/router.js'
import store from '@/store/store.js'
import i18n from '@/i18n'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource' // @todo utilisé qu'une seule fois
import VuePlyr from 'vue-plyr'
import Acl from 'vue-acl'
import VueLocalStorage from 'vue-localstorage'
import PortalVue from 'portal-vue'
import VueUploadComponent from 'vue-upload-component'
import vueHtml from 'vue-html'

import '@/libs/mq/index.js'

// Custom components
import ErrorManager from '@/plugins/ErrorManager'
import PiContactUser from '@/components/Contact/User.vue'
import PiContactDocument from '@/components/Contact/Document.vue'
import PiUploadsPreview from '@/components/Ui/Preview.vue'
import PiVideoPlayer from '@/components/Templates/PiVideoPlayer/PiVideoPlayer.vue'
import './helpers/context'
// Directives
import SignalDirective from '@/directives/signal.js'
import ExposantDirective from '@/directives/exposant.js'
// App

// Assets
import '@gup/fonts/Pi.font.js'
import '@gup/fonts/PiCompetencies.font.js'
import '@gup/fonts/PiJobs.font.js'
import '@gup/fonts/font-awesome.font.js'
import '@/assets/style/main.sass'
import 'vue-plyr/dist/vue-plyr.css'
// Mixins
import UtilMixin from '@/mixins/Utils.js'
import AuthentificationMixin from '@/mixins/Authentification.js'
import ContactMixin from '@/mixins/Contact.js'
import VideoMixin from '@/mixins/Video.js'
import ResourcesMixin from '@/mixins/Resources.js'
import ContextMixin from '@/mixins/Context.js'
import vuescroll from 'vuescroll'
import VueObserveVisibility from 'vue-observe-visibility'

// Import Froala Editor
import 'froala-editor/js/plugins.pkgd.min.js'
// Import third party plugins
import 'froala-editor/js/plugins/link.min'
import VueFroala from 'vue-froala-wysiwyg'

/* eslint-disable no-unused-vars */
/* eslint-disable no-new */
/* eslint-disable no-undef */
/* eslint-disable no-extend-native */

// Require Froala Editor js file.
require('froala-editor/js/froala_editor.pkgd.min.js')

// Require Froala Editor css files.
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')

// Components
function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[[]]/g, '\\$&')
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

if (getParameterByName('idT') && getParameterByName('idU')) {
  window.localStorage.setItem('id_token', getParameterByName('idT'))
  window.localStorage.setItem('id_user', getParameterByName('idU'))
}

const App = () => import('@/App')

// Enregistrement de la page demandée :
if (window.location.pathname !== '/404' && window.location.pathname !== '/login') {
  window.localStorage.setItem('main.request.url', window.location.pathname)
} else {
  window.localStorage.removeItem('main.request.url')
}
// Implementation dans le composant Vue
Vue.use(VueFroala)
Vue.use(VueObserveVisibility)
Vue.use(VuePlyr)
Vue.use(AsyncComputed, {})

Vue.use(ElementUi)
Vue.use(PortalVue)
Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(VueLocalStorage)
Vue.use(Acl, {
  router,
  init: '',
})

// Custom Plugins
Vue.use(ErrorManager)
Vue.use(vueHtml)
Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      mode: 'native',
      sizeStrategy: 'percent',
      detectResize: true,
      /** Enable locking to the main axis if user moves only slightly on one of them at start */
      locking: true,
    },
    rail: {
      size: '10px',
    },
    bar: {
      showDelay: 500,
      onlyShowBarOnScroll: true,
      keepShow: true,
      background: '#c1c1c1',
      opacity: 1,
      hoverStyle: false,
      specifyBorderRadius: false,
      minSize: 0.125,
      size: '10px',
      disable: false,
    },
  },
})
// Implementation Mixins
Vue.mixin(UtilMixin)
Vue.mixin(ResourcesMixin)
Vue.mixin(AuthentificationMixin)
Vue.mixin(ContactMixin)
Vue.mixin(VideoMixin)
Vue.mixin(ContextMixin)

Vue.component('file-upload', VueUploadComponent)
Vue.component('pi-contact-user', PiContactUser)
Vue.component('pi-contact-document', PiContactDocument)
Vue.component('pi-uploads-preview', PiUploadsPreview)
Vue.component('pi-video-player', PiVideoPlayer)
Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  },
})
Vue.directive('signal', SignalDirective)
Vue.directive('exposant', ExposantDirective)

// Locales
Vue.config.lang = 'fr'
Vue.config.silent = true

// Application
window.app = new Vue({
  i18n,
  store,
  methods: {},
  render: (h) => h(App),
  router,
})

app.$mount('#app')

export default app
