import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import store from '@/api'
import moment from 'moment'

const ClassProgramSchema = new Schema({
  title: 'ClassProgram Schema',
  description: 'Schema for ClassProgram Records.',
  type: 'object',
  properties: {
    additionalProperties: true,
    id: {
      type: 'string',
    },
    programId: {
      type: 'string',
    },
    classId: {
      type: 'string',
    },
    order: {
      type: 'number',
    },
  },
})
export class ClassProgramRecord extends Record {
  get openingBoundaries () {
    let from = moment(this.classe.startAt)
    let to = moment(this.classe.expireAt)
    if (this.startAt && from < moment(this.startAt)) {
      from = moment(this.startAt)
    }
    if (this.expireAt && to > moment(this.expireAt)) {
      to = moment(this.expireAt)
    }
    return { from, to }
  }

  async resetMe () {
    const datas = await this.constructor.mapper.reset(this.id, {})
    return datas.data
  }
}

export const ClassProgram = store.defineMapper('ClassProgram', {
  schema: ClassProgramSchema,
  endpoint: 'class-programs',
  relations: {
    belongsTo: {
      Program: {
        localField: 'program',
        foreignKey: 'programId',
      },
      Class: {
        localField: 'classe',
        foreignKey: 'classId',
      },
    },
  },
  recordClass: ClassProgramRecord,
})

addActions({
  reset: {
    pathname: 'reset/me',
    method: 'POST',
  },
})(ClassProgram)
