import { Record, Schema } from 'js-data'
import Mapper from '../mapper.js'
import store from '@/api'
import {
  filter,
  findIndex,
  get,
  hasIn,
  isUndefined,
  sortBy,
} from 'lodash'

const CourseSchema = new Schema({
  title: 'Coursetitle',
  description: 'Schema for Course Records.',
  type: 'object',
  properties: {
    categoryId: {
      type: ['string', 'null'],
    },
    typeId: {
      type: ['string', 'null'],
    },
    state: {
      type: 'string',
      enum: ['DRAFT', 'PUBLISHED', 'CLOSED'],
    },
    video: {
      type: ['string', 'null'],
    },
  },
  additionalProperties: true,
  required: ['title', 'categoryId', 'state'],
})
class CourseRecord extends Record {
  loadTree () {
    return store.findAll('CourseNode', { filter: { courseId: this.id } }, { force: true, raw: true }).then(res => {
      const records = res.data
      this.tree = sortBy(filter(records, { type: 'root' }), ['order'])
      return this
    })
  }

  getNextStep (id) {
    if (isUndefined(id) && hasIn(this.steps, '0')) {
      return this.steps[0]
    }
    const currentIndex = findIndex(this.steps, { id })
    if (currentIndex < 0) {
      return undefined
    } else if (currentIndex + 1 >= this.steps.length) {
      return undefined
    }
    return get(this, `steps.${currentIndex + 1}`)
  }

  loadSteps () {
    const flatten = function (tree) {
      const flat = []
      for (const r in tree) {
        const rootChildren = tree[r].childrenList
        for (const s in rootChildren) {
          const sectionChildren = rootChildren[s].childrenList
          for (const b in sectionChildren) {
            const brick = sectionChildren[b]
            flat.push(brick)
          }
        }
      }
      return flat
    }
    return new Promise((resolve, reject) => {
      if (this.tree) {
        this.steps = flatten(this.tree)
        resolve(this)
      } else {
        this.loadTree().then(course => {
          this.steps = flatten(this.tree)
          resolve(this)
        })
      }
    })
  }
}

const Course = store.defineMapper('Course', {
  mapperClass: Mapper,
  schema: CourseSchema,
  endpoint: 'courses',
  relations: {
    belongsTo: {
      CourseCategory: {
        localField: 'category',
        localKey: 'categoryId',
      },
      CourseType: {
        localField: 'type',
        localKey: 'typeId',
      },
    },
    hasMany: {
      CourseNode: {
        localField: 'nodes',
        foreignKey: 'courseId',
      },
      ProgramCourse: {
        localField: 'programCourses',
        foreignKey: 'courseId',
      },
      Lesson: {
        foreignKey: 'courseId',
        localField: 'lesson',
      },
    },
  },
  recordClass: CourseRecord,
})

export default Course
