import { Schema } from 'js-data'
import Mapper from '../mapper.js'
import store from '@/api'

const ClassUserSchema = new Schema({
  title: 'ClassUserSchemaTitle',
  description: 'Schema for ClassUser Records.',
  type: 'object',
  properties: {
    classId: {
      type: 'string',
    },
    userId: {
      type: 'string',
    },
  },
  additionalProperties: true,
})

export const ClassUser = store.defineMapper('ClassUser', {
  mapperClass: Mapper,
  ClassUserSchema,
  endpoint: 'class-users',
  relations: {
    belongsTo: {
      Class: {
        parent: true,
        localField: 'class',
        localKey: 'classId',
      },
      AppUser: {
        localField: 'user',
        localKey: 'userId',
      },
    },
  },
})
