import { Record, Schema } from 'js-data'
import store from '@/api'
import { isEmpty, isUndefined, map } from 'lodash'
import Mapper from '../mapper.js'
import { INTERNAL_GENERIC_CONTEXT_ID } from './Context.js'

class CourseCompetencyRecord extends Record {
  get path () {
    const output = [this]
    if (!isUndefined(this.parent)) {
      output.unshift(...this.parent.path)
    } else if (!isEmpty(this.parentId)) {
      output.unshift({ title: this.parentId, id: this.parentId })
    }
    return output
  }

  get pathTitle () {
    const { path } = this
    return map(path, (o) => { return o.title }).join(' / ')
  }

  get contextualizedPathId () {
    return [this.contextId || INTERNAL_GENERIC_CONTEXT_ID, ...this.pathId]
  }

  get pathId () {
    const { path } = this
    return map(path, (o) => { return o.id })
  }

  get iconFallBack () {
    const { path } = this
    for (let i = 0; i < path.length; i++) {
      if (path[i].icon) {
        return path[i].icon
      }
    }
    return undefined
  }
}

const CourseCompetencySchema = new Schema({
  title: 'CourseCompetencyShemaTitle',
  description: 'Schema for CourseCompetency Records.', // optional
  type: 'object',
  properties: {
    parentId: {
      type: ['string', 'null'],
    },
  },
  additionalProperties: true,
})

export const CourseCompetency = store.defineMapper('CourseCompetency', {
  mapperClass: Mapper,
  schema: CourseCompetencySchema,
  endpoint: 'course-competencies',
  relations: {
    belongsTo: {
      CourseCompetency: {
        localField: 'parent',
        localKey: 'parentId',
      },
    },
    hasMany: {
      CourseBlock: {
        localField: 'blocks',
        foreignKey: 'competencyId',
      },
      CourseCompetency: {
        localField: 'children',
        foreignKey: 'parentId',
      },
    },
  },
  recordClass: CourseCompetencyRecord,
})
