import { Schema } from 'js-data'
import store from '@/api'
import Mapper from '../mapper.js'

const CourseTermSchema = new Schema({
  title: 'CourseTermtitle',
  description: 'Schema for CourseTerm Records.', // optional
  type: 'object',
  properties: {
    title: {
      type: 'string',
    },
    words: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    definition: {
      type: 'string',
    },
  },
  required: ['title', 'words', 'definition'],
})

export const CourseTerm = store.defineMapper('CourseTerm', {
  mapperClass: Mapper,
  schema: CourseTermSchema,
  endpoint: 'course-terms',
  relations: {
    belongsTo: {
      CourseBlock: {
        localField: 'block',
        foreignKey: 'termIds',
      },
    },
  },
})
