<script>
import alert from 'element-ui/packages/alert/src/main.vue'
export default {
  name: 'pi-alert',
  extends: alert,
  methods: {
    close () {
      this.$emit('close')
    },
  },
}
</script>
