import { DataStore, utils } from 'js-data'
import { HttpAdapter } from 'js-data-http'
import { isArray, isPlainObject } from 'lodash'

// Adapter & Data Storage
const config = {
  debug: true,
  usePendingFindAll: true,
  usePendingFind: true,
}

class ApiAdapter extends HttpAdapter {
  responseError (err, config, opts) {
    if (err.response.status === 401 && err.response.config.pathname !== 'me') {
      document.location.href = '/login'
      return
    }
    return HttpAdapter.prototype.responseError.call(this, err, config, opts)
  }

  update (mapper, id, props, opts) {
    if (!opts.forceReplace) {
      // opts has the finaly say over the method used
      opts.method = opts.method || 'patch'

      // We need the record to get changes
      const record = mapper.datastore.get(mapper.name, id)
      if (record) {
        // Now we have two possible cases :
        // * either props contain parameters the user want to update, and only
        //   those should be updated
        // * or when record is saved, props contains all data from the record
        //   even unchanged data
        // So we can't use record.changes(), because if first case it will be
        // empty or with not the data we want to send. Thus we can do the same
        // things the changes method do, but with props rather than with
        // current attributes.

        // opts.changes = record.changes();
        const changes = utils.diffObjects(props, record._get('previous'), opts)
        props = utils.deepMixIn(
          utils.deepMixIn({}, changes.changed),
          changes.added,
        )
      }
    }
    return HttpAdapter.prototype.update.call(this, mapper, id, props, opts)
  }

  deserialize (mapper, response, opts) {
    if (opts.import === false) {
      return HttpAdapter.prototype.deserialize.call(this, mapper, response, opts)
    }
    if (isArray(response.data)) {
      response.data.forEach(el => {
        store.add(opts.name, el)
      })
    } else if (opts.method === 'patch' && isPlainObject(response.data)) {
      store.add(opts.name, response.data)
    }
    return HttpAdapter.prototype.deserialize.call(this, mapper, response, opts)
  }

  serialize (mapper, data, opts) {
    if (data && isPlainObject(data)) {
      const el = data
      for (const key in el) {
        // suppression des clefs privées
        if (key.indexOf('_') === 0) {
          delete el[key]
        }
        // suppression des clefs avec track === false
        if (mapper.schema?.properties?.[key]?.track === false) {
          delete el[key]
        }
      }
    }
    return HttpAdapter.prototype.serialize.call(this, mapper, data, opts)
  }
}

const storage = window.localStorage
const store = new DataStore(config)

const jsonApiAdapter = new ApiAdapter({
  store,
})

store.registerAdapter('jsonApi', jsonApiAdapter, { default: true })
if (storage.getItem('id_token')) {
  store.getAdapter('jsonApi').http.defaults.headers = {
    common: {
      Authorization: `Bearer ${storage.getItem('id_token')}`,
    },
  }
}
window.store = store
export default store
