import Vue from 'vue'
import { forOwn } from 'lodash'
const state = () => ({
  menu: {
    collapsed: true,
  },
  competencies: {},
  chartTimeZone: null,
})

// getters
const getters = {
  isMenuCollapsed (state) {
    return state.menu.collapsed
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  SET_MENU_COLLAPSE (state, value) {
    state.menu.collapsed = value
  },
  SET_COMPETENCY_ENABLE (state, id) {
    Vue.set(state.competencies, id, true)
  },
  SET_COMPETENCY_DISABLE (state, id) {
    Vue.set(state.competencies, id, false)
  },
  SET_CHART_TIMEZONE (state, obj) {
    Vue.set(state, 'chartTimeZone', obj)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
