import { Schema } from 'js-data'
import store from '@/api'
import Mapper from '../mapper.js'

const CompanyUserSchema = new Schema({
  title: 'CompanyUserSchemaTitle',
  description: 'Schema for CompanyUser Records.',
  type: 'object',
  properties: {
    companyId: {
      type: 'string',
    },
    userId: {
      type: 'string',
    },
    role: {
      type: 'string',
      enum: ['OWNER', 'ADMIN', 'ORGANIZER', 'TEACHER', 'STUDENT', 'CERTIFIER', 'SUPERVISOR'],
    },
  },
  additionalProperties: true,
})

export const CompanyUser = store.defineMapper('CompanyUser', {
  mapperClass: Mapper,
  schema: CompanyUserSchema,
  endpoint: 'company-users',
  relations: {
    belongsTo: {
      Company: {
        localField: 'company',
        localKey: 'companyId',
      },
      AppUser: {
        localField: 'user',
        localKey: 'userId',
      },
    },
  },
})
