<template lang="pug">
  doctype html
  .pi-contact-document
    el-dialog.dark(:visible.sync="isVisible", :title="title", :class="width")
      el-form(:ref="formName")
        el-form-item(label="Objet")
          el-input(v-model="object", disabled)
        el-form-item(label="Fichier(s)")
        div(style="display:flex; flex-wrap:wrap;")
          template(v-for="fileRecord in fileRecords")
            pi-uploads-preview.block-center(
              v-if="fileRecord"
              style="width: 200px",
              :ratio='.5',
              :value="fileRecord",
              theme='dark',
              :icon="false"
              )
        .text-align-center.space-before
          el-button.btn__square.bg-primary(@click="submit", :loading="isSending") {{ $t('actions.receiveByMail.action') }}
            i.el-icon-pi-fleche-bouton

        .alerts.space-before(v-for="(alert, index) in alerts")
          pi-alert.little-margin-top(:title='alert.title', :type="alert.type", show-icon, @close="alerts.splice(index, 1)")
</template>
<script>

import store from '@/api'
import {
  get,
  isArray,
  isEmpty,
  isNull,
} from 'lodash'
const PiAlert = () => import('@/components/Ui/PiAlert.vue')

export default {
  name: 'pi-contact-document',
  components: { PiAlert },
  filters: {
  },
  data () {
    return {
      formName: 'document',
      isSending: false,
      alerts: [],
    }
  },
  computed: {
    width () {
      return get(this.files, 'length', 0) > 4 ? 'many-documents' : 'fe-documents'
    },
    title () {
      return 'Recevoir la documentation par mail'
    },
    files () {
      return this.$store.state.contact.files || []
    },
    isVisible: {
      get () {
        return !isNull(this.files) && !isEmpty(this.files)
      },
      set (value) {
        if (!value) {
          this.$store.commit('CONTACTFORM_HIDE')
        }
      },
    },
    fileRecords () {
      if (!isArray(this.files)) {
        return []
      }
      return this.files.map(file => {
        this.$store.getters.DSAppFile[file]
        return store.get('AppFile', file)
      })
    },
    object () {
      return 'Programme PI : Documentation'
    },
  },
  watch: {
    isVisible (newValue, oldValue) {
      if (!newValue) {
        this.$set(this, 'alerts', [])
      }
    },
  },
  created () {
    window._cd = this
  },
  methods: {
    submit () {
      const message = 'Vous trouverez ci-contre le ou les ressources que vous avez souhaité recevoir par mail :'
      this.$set(this, 'alerts', [])
      if (this.currentUserRecord && this.files.length > 0) {
        this.isSending = true
        this.currentUserRecord.notifyFiles(message, this.files).then(response => {
          this.alerts.push({
            title: 'Message envoyé',
            type: 'success',
          })
          setTimeout(() => {
            this.isVisible = false
            this.contactAutoHide()
          }, 1500)
        }).catch(err => {
          const errorCode = get(err, 'response.data.code', 'UNDEFINED')
          this.alerts.push({
            title: this.$t(`errors.${errorCode}.title`),
            type: 'error',
          })
        }).then(() => {
          this.isSending = false
        })
      } else {
        this.alerts.push({
          title: 'Des données sont manquantes pour satisfaire votre requète',
          type: 'error',
        })
      }
    },
  },
  asyncComputed: {
  },
}

</script>
