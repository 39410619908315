import { Record, Schema } from 'js-data'
import Mapper from '../mapper.js'
import store from '@/api'
import { flatten, get, isArray, map, uniq } from 'lodash'
import utils from '@/helpers/utils.js'

export const MIME_TYPES = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  GIF: 'image/gif',
  PDF: 'application/pdf',
  MPEG: 'audio/mpeg',
  MP3: 'audio/mp3',
}
const TYPES = {
  IMAGE: 'image',
  PDF: 'pdf',
  AUDIO: 'audio',
}
const MIME_TYPE_TO_FILE_TYPE = {
  [MIME_TYPES.JPEG]: TYPES.IMAGE,
  [MIME_TYPES.PNG]: TYPES.IMAGE,
  [MIME_TYPES.GIF]: TYPES.IMAGE,
  [MIME_TYPES.PDF]: TYPES.PDF,
  [MIME_TYPES.MPEG]: TYPES.AUDIO,
  [MIME_TYPES.MP3]: TYPES.AUDIO,
}
export const MIME_TYPE_IMAGE = [
  MIME_TYPES.JPEG,
  MIME_TYPES.PNG,
  MIME_TYPES.GIF,
]

export const MIME_TYPE_AUDIO = [
  MIME_TYPES.MPEG,
  MIME_TYPES.MP3,
]

export const MIME_TYPE_PDF = [
  MIME_TYPES.PDF,
]

const fileTypeConfiguration = {
  image: {
    icon: 'fa-file-image-o',
    previewable: true,
  },
  picture: {
    icon: 'fa-file-image-o',
    previewable: false,
  },
  pdf: {
    icon: 'fa-file-pdf-o',
    previewable: false,
  },
  file: {
    icon: 'fa-file-o',
    previewable: false,
  },
  audio: {
    icon: 'fa-file-audio-o',
    previewable: false,
  },
}
function typesToMimeTypes (types) {
  if (!isArray(types)) {
    return []
  }
  return uniq(flatten(map(types, function (type) {
    const mimeTypes = []
    for (const mime in MIME_TYPE_TO_FILE_TYPE) {
      if (MIME_TYPE_TO_FILE_TYPE[mime] === type) {
        mimeTypes.push(mime)
      }
    }
    return mimeTypes
  })))
}

class AppFileRecord extends Record {
  get download () {
    return utils.getFilePath(this.id)
  }

  get fileType () {
    return get(MIME_TYPE_TO_FILE_TYPE, this.type, 'file')
  }

  get icon () {
    const type = get(MIME_TYPE_TO_FILE_TYPE, this.type, 'file')
    return get(fileTypeConfiguration, `${type}.icon`, 'fa-file-o')
  }

  get fileNameWithoutExtension () {
    return this.name.replace(/\.[^/.]+$/, '')
  }
}

const AppFileSchema = new Schema({
  title: 'AppFileSchemaTitle',
  description: 'Schema for AppFile Records.',
  type: 'object',
  pi: {
    fileTypeConfiguration,
    MIME_TYPE_TO_FILE_TYPE,
    typesToMimeTypes,
    defaultTypes: [TYPES.IMAGE],
  },
  properties: {
    libraryId: {
      type: 'string',
    },
    ownerId: {
      type: 'string',
    },
  },
  additionalProperties: true,
})

export const AppFile = store.defineMapper('AppFile', {
  mapperClass: Mapper,
  schema: AppFileSchema,
  endpoint: 'files',
  relations: {
    belongsTo: {
      Library: {
        parent: true,
        localKey: 'libraryId',
        localField: 'library',
      },
    },
  },
  recordClass: AppFileRecord,
})
