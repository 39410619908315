import Vue from 'vue'
import { get, isArray, isEqual } from 'lodash'

const { set, delete: remove } = Vue

const MUTATION = 'datastore/REFRESH_DATASTORE'
const MUTATION_DELETE = 'datastore/DELETE'
let DStore

export default function (_DStore, {
  namespace = 'DS',
  silent = true,
} = {}) {
  silent = false
  DStore = _DStore
  if (!DStore) {
    console.warn('You must initialize vuex-jsdata-plugin with a DS store object from js-data')
    return
  }
  return function (store) {
    const ressources = Object.values(DStore._collections)
    const getters = {}
    const moduleState = {}
    set(store.state, namespace, {})
    getters[namespace] = (state) => state[namespace]

    ressources.forEach((ressource) => {
      const ressourceName = ressource.mapper.name
      const key = `${namespace}${ressourceName}`
      getters[key] = (state) => state[ressourceName]
      set(moduleState, ressourceName, {})
    })

    const module = {
      state: moduleState,
      getters,
      mutations: {
        [MUTATION] (state, { type, data }) {
          const { id } = data
          const namespace = state[type]
          const newInstance = JSON.parse(JSON.stringify(data))
          if (!namespace[id]) {
            set(namespace, id, { id, ...newInstance }) // assign to trigger reactivity
          } else {
            const oldInstance = JSON.parse(JSON.stringify(namespace[id]))
            if (!isEqual(newInstance, oldInstance)) {
              set(namespace, id, { id, ...newInstance }) // assign to trigger reactivity
            }
          }
        },
        [MUTATION_DELETE] (state, { type, data }) {
          const { id } = data
          const namespace = state[type]
          remove(namespace, id)
        },
      },
    }
    store.registerModule('DS', module)
    function commitRefresh (res, data) {
      const commit = instance => {
        store.commit(MUTATION, {
          type: res,
          data: instance,
        }, { silent })
      }
      if (Array.isArray(data)) data.forEach(commit)
      else commit(data)
    }
    function commitDelete (res, data) {
      const commit = instance => {
        store.commit(MUTATION_DELETE, {
          type: res,
          data: instance,
        }, { silent })
      }
      if (Array.isArray(data)) data.forEach(commit)
      else commit(data)
    }
    const refreshCb = function refreshCb (res, data) {
      if (!isArray(data) && isArray(data.data)) {
        ({ data } = data)
      }
      return commitRefresh(res, data)
    }
    DStore.on('refresh', (mapperName, data) => {
      commitRefresh(mapperName, data)
    })
    DStore.on('afterDestroy', (mapperName, data) => {
      commitDelete(mapperName, data)
      commitRefresh(mapperName, data)
    })
    DStore.on('remove', (mapperName, data) => {
      commitDelete(mapperName, data)
    })
    DStore.on('removeAll', (mapperName, data) => {
      commitDelete(mapperName, data)
    })
    DStore.on('change', (mapperName, data) => {
      return commitRefresh(mapperName, data)
    })
    DStore.on('afterCreate', function (res, query, event, datas) {
      refreshCb(res, datas)
    })
    DStore.on('add', function (res, datas) {
      refreshCb(res, datas)
    })
    DStore.on('afterFindAll', function (res, query, event, datas) {
      refreshCb(res, datas)
    })
    DStore.on('afterFind', function (res, query, event, datas) {
      refreshCb(res, [datas])
    })
    DStore.on('afterInject', (mapperName, data) => {
      refreshCb(mapperName, data)
    })
  }
}

export function mapRessources (ressources = []) {
  function generateGetter (name, key) {
    return function getter () {
      const id = get(this, key)
      if (id === null || id === undefined || !this.$store.state.DS[name][id]) {
        console.warn(`no ressource with id:${id}`)
        return undefined
      }
      return DStore.get(name, id)
    }
  }
  const ressourceGetters = ressources.reduce((sum, ressource) => {
    const getterName = Object.keys(ressource)[0]
    ressource[getterName] = generateGetter(...ressource[getterName])
    return Object.assign(sum, ressource)
  }, {})
  return ressourceGetters
}
