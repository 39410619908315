import { isString } from 'lodash'
export function untrailingSlashIt (str) {
  if (!isString(str)) {
    return str
  }
  return str.replace(/\/$/, '')
}
export function trailingSlashIt (str) {
  if (!isString(str)) {
    return str
  }
  return `${untrailingSlashIt(str)}/`
}
