// https://fr.vuejs.org/v2/guide/custom-directive.html
import { get } from 'lodash'

function transposeExposantsToHtml (el, vnode) {
  for (let childIndex = 0; childIndex < vnode.children.length; childIndex++) {
    const element = vnode.children[childIndex]
    if (element.text) {
      let text = get(vnode, ['children', childIndex, 'text'], '')
      const superCharacters = 'ⁱ⁺⁼⁽⁾⁻⁰¹²³⁴⁵⁶⁷⁸⁹'
      const normaCharacters = 'i+=()-0123456789'
      for (let index = 0; index < superCharacters.length; index++) {
        const superCharacter = superCharacters[index]
        const normaCharacter = `<sup>${normaCharacters[index]}</sup>`
        const reg = new window.RegExp(superCharacter, 'g')
        text = text.replace(reg, normaCharacter)
      }
      text = text.replace(/<\/sup>((?:\s|&nbsp;)*)<sup>/g, '$1')
      const doc = new window.DOMParser().parseFromString(`<span>${text}</span>`, 'text/html')
      el.replaceChild(doc.body.querySelector('span'), el.childNodes[childIndex])
    }
  }
}

export default {
  // définition de la directive
  inserted: function (el, binding, vnode, oldVnode) {
    transposeExposantsToHtml(el, vnode)
  },
  update: function (el, binding, vnode, oldVnode) {
    transposeExposantsToHtml(el, vnode)
  },
}
