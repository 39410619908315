
import store from '@/api'
const state = {
}

// getters
const getters = {
  rootsCategories (state, getters) {
    getters.DSCourseCategory
    const rootsCategories = store.filter('CourseCategory', { where: {}, orderBy: ['title'] })
    return rootsCategories
  },
  rootsCourseTypes (state, getters) {
    getters.DSCourseType
    const rootsCourseTypes = store.filter('CourseType', { where: {}, orderBy: ['title'] })
    return rootsCourseTypes
  },
}

// actions
const actions = {
  LOAD_REQUIRED_DATA () {
    return store.findAll('CourseCompetency', { filter: {}, options: { sort: 'order' } }, { force: true })
      .then(() => {
        return store.findAll('CourseCategory', {}, { force: true })
      }).then(() => {
        return store.findAll('CourseType', {}, { force: true })
      })
  },
  LOAD_COURSESTYPES () {
    return store.findAll('CourseType', {}, { force: true })
  },
  loadUserPermissions ({ dispatch }) {
    return dispatch('LOAD_REQUIRED_DATA')
  },
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations,
}
