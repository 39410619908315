import store from '@/api'
import Mapper from '../mapper.js'
import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import { get, isNumber, isUndefined, orderBy } from 'lodash'

function pageNavigate (response) {
  const page = store.add('LessonPage', response.data)
  return page
}
export class LessonRecord extends Record {
  static getActiveLessonOnDashboard () {
    return LessonRecord.getActiveDatasOnDashboard()?.lessonId
  }

  static getActiveClassOnDashboard () {
    return LessonRecord.getActiveDatasOnDashboard()?.classId
  }

  static getActiveDatasOnDashboard () {
    const s = window.sessionStorage.getItem('dashboardActiveDatas')
    if (!s) return
    try {
      return JSON.parse(s)
    } catch (error) {
      console.error(error)
    }
  }

  setAsActiveOnDashboard () {
    window.sessionStorage.setItem('dashboardActiveDatas', JSON.stringify({
      classId: this.classId,
      lessonId: this.id,
    }))
  }

  get interactiveItems () {
    return get(this.actions, 'interactive', [])
  }

  get isEnded () {
    return this.state === 'SKIPPED' || this.progress === 'COMPLETED'
  }

  get certificateUrl () {
    if (!this.isEnded || !this.certificationRef) {
      return
    }
    return this.certificationRef
  }

  get sortedTree () {
    return orderBy(this.tree, 'order', 'asc')
  }

  get scoreRatio () {
    if (
      isNumber(get(this, 'scoring.global.actual')) &&
      isNumber(get(this, 'scoring.global.max')) &&
      this.scoring.global.max > 0
    ) {
      return this.scoring.global.actual / this.scoring.global.max
    }
    return 0
  }

  get percentProgression () {
    const sectionsQty = get(this, 'tree.length', 0)
    let totalpercent = 0
    this.sortedTree.forEach(section => {
      let percent = 0
      if (section.pages && section.pages.length > 0) {
        let done = 0
        const todo = section.pages.length
        section.pages.forEach(page => {
          if (page.progress === 'COMPLETED') {
            done++
          } else if (page.progress === 'ONGOING') {
            done += 0.5
          }
        })
        percent = done / todo
      }
      totalpercent += percent / sectionsQty
    })
    return totalpercent
  }

  getTree (force = false) {
    return new Promise((resolve, reject) => {
      resolve(this.sortedTree)
    })
  }

  getCurrentPageHints () {
    return this.constructor.mapper.getCurrentPageHints(this.id).then(response => {
      const page = store.add('LessonPage', response.data)
      return page
    })
  }

  getCurrentPage (opts) {
    return this.constructor.mapper.getCurrentPage(this.id, { params: { noCache: (new Date()).getTime(), ...opts } }).then(response => {
      const page = store.add('LessonPage', response.data)
      return page
    })
  }

  sendActions (actionIdx, choiceIdx) {
    if (isUndefined(actionIdx) || isUndefined(choiceIdx)) {
      console.warn('On cherche à envoyer des données manquantes !')
    }
    return this.constructor.mapper.actions(this.id, { data: { inputs: [{ actionIdx, choiceIdx }] } }).then(response => {
      console.log('[ LESSON - ACTIONS ]', response)
    })
  }

  getNextPage (opts) {
    return this.constructor.mapper.nextPage(this.id, { params: opts }).then(response => {
      const page = store.add('LessonPage', response.data)
      return page.loadRelations('node')
    })
  }

  emptyPatchCurrentPage (id, datas) {
    return this.constructor.mapper.patchCurrentPage(this.id, {
      data: {},
    }).then(response => {
      const page = store.add('LessonPage', response.data)
      return page
    })
  }

  patchCurrentPage (id, datas) {
    return this.constructor.mapper.patchCurrentPage(this.id, {
      data: {
        inputs: {
          answers: datas,
        },
      },
    }).then(response => {
      const page = store.add('LessonPage', response.data)
      return page
    })
  }

  get program () {
    return store.get('ProgramCourse', this.programCourseId)?.program
  }

  nextPage (fromPageId) {
    return this.constructor.mapper.nextPage(this.id, { params: { fromPageId } })
      .then(pageNavigate)
  }

  previousPage (fromPageId) {
    return this.constructor.mapper.previousPage(this.id, { params: { fromPageId } })
      .then(pageNavigate)
  }

  ping () {
    return this.constructor.mapper.getCurrentPage(this.id, { params: { noCache: (new Date()).getTime() } })
  }

  sendCustomTincanScore (pageId, datas) {
    return this.constructor.mapper.customTincanScore(this.id, {
      data: {
        pageId,
        ...datas,
      },
    })
  }
}

const LessonSchema = new Schema({
  title: 'Lessontitle',
  description: 'Schema for Lesson Records.', // optional
  type: 'object',
  required: ['classId'],
  properties: {
    startAt: {
      type: 'string',
    },
    userId: {
      type: 'string',
    },
    classId: {
      type: 'string',
    },
    courseId: {
      type: 'string',
    },
    currentNodeId: {
      type: ['string', 'null'],
    },
    enabledSectionIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    timeLimit: {},
    timeSpent: {},
    timeAlive: {},
    programCourseId: {
      type: 'string',
    },
    state: {
      type: 'string',
      enum: ['LOCKED', 'AVAILABLE', 'HIDDEN', 'DONE', 'SKIPPED', 'EXPIRED'],
    },
    certificationRef: {
      type: 'string',
    },
  },
})

export const Lesson = store.defineMapper('Lesson', {
  mapperClass: Mapper,
  schema: LessonSchema,
  endpoint: 'lessons',
  relations: {
    hasMany: {
      LessonPage: {
        foreignKey: 'lessonId',
        localField: 'pages',
      },
    },
    belongsTo: {
      ClassProgram: {
        localKey: 'classProgramId',
        localField: 'classProgram',
      },
      Course: {
        localKey: 'courseId',
        localField: 'course',
      },
      AppUser: {
        localKey: 'userId',
        localField: 'appUser',
      },
      Class: {
        localKey: 'classId',
        localField: 'class',
      },
    },
  },
  recordClass: LessonRecord,
})

addActions({
  getCurrentPage: {
    pathname: 'pages/current',
    method: 'GET',
  },
  actions: {
    pathname: 'actions',
    method: 'POST',
  },
  patchCurrentPage: {
    pathname: 'pages/current',
    method: 'PATCH',
  },
  customTincanScore: {
    pathname: 'pages/custom-tincan-score',
    method: 'POST',
  },
  nextPage: {
    pathname: 'pages/next',
    method: 'POST',
  },
  previousPage: {
    pathname: 'pages/previous',
    method: 'POST',
  },
  getCurrentPageHints: {
    pathname: 'pages/current/hints',
    method: 'POST',
  },
})(Lesson)
