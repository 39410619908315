/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
// * @info - We can't use JSData because our UserSession isn't a collection of entities

//  @info - Imports
import Vue from 'vue'
import router from '../../router.js'
import ConfigurationApi from '../../api/configuration.js'
import store from '@/api/index.js'
import { compact, filter, forOwn, get, includes, isArray, isUndefined, map, set, uniq } from 'lodash'
import axios from 'axios'

//  @info - URL and endpoint constants
const API_URL = () => ConfigurationApi.basePath
const USERS_URL = () => `${API_URL()}users/`
const LOGIN_URL = () => `${USERS_URL()}login`
const ME_URL = () => `${USERS_URL()}me`
// const LOGOUT_URL = USERS_URL + '/logout/'
const storage = window.localStorage
const ALLOWED_ROLES = ['TEACHER', 'ADMIN', 'OWNER', 'ORGANIZER', 'CERTIFIER', 'SUPERVISOR']

function updateAdapter () {
  store.getAdapter('jsonApi').http.defaults.headers = {
    common: { Authorization: `Bearer ${storage.getItem('id_token')}` },
  }
  if (process.env.NODE_ENV !== 'production' && process.env.VUE_APP_LOCAL === 'true' && ConfigurationApi['X-Host']) {
    store.getAdapter('jsonApi').http.defaults.headers.common['X-Host'] = ConfigurationApi['X-Host']
  }
  if (process.env.NODE_ENV !== 'production' && process.env.VUE_APP_LOCAL === 'true' && ConfigurationApi['X-Origin']) {
    store.getAdapter('jsonApi').http.defaults.headers.common['X-Origin'] = ConfigurationApi['X-Origin']
  }
}

const vueHttpConfig = () => {
  const headers = {}
  if (process.env.NODE_ENV !== 'production' && process.env.VUE_APP_LOCAL === 'true' && ConfigurationApi['X-Host']) {
    headers['X-Host'] = ConfigurationApi['X-Host']
  }
  if (process.env.NODE_ENV !== 'production' && process.env.VUE_APP_LOCAL === 'true' && ConfigurationApi['X-Origin']) {
    headers['X-Origin'] = ConfigurationApi['X-Origin']
  }

  return headers
}

const state = () => ({
  connecting: true,
  // cgu,
  user: {
    id: null,
    token: null,
    mail: null,
    authenticated: false,
    sandboxMode: (storage.getItem('id_token') === 'SANDBOX_TOKEN'),
    roles: [],
    permissions: [],
    domain: null,
  },
})

const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  SET_USER (state, user) {
    // @todo - on ne copie que les propriétés autorisées (sur les autres SETTERS)
    const properties = [
      'id',
      'email',
      'token',
      'roles',
      'permissions',
      'createdAt',
      'firstname',
      'lastname',
      'displayName',
      'birthday',
      'legal',
      'domain',
      'avatarId',
      'classUsers',
      'companyUsers',
      'role-users',
    ]
    for (const property in user) {
      Vue.set(state.user, property, user[property])
    }
    if (!state.user.id) Vue.set(state.user, 'id', user._id)
    properties.forEach(function (property) {
      if (user[property]) {
        Vue.set(state.user, property, user[property])
      }
    }, this)
  },
  CONNECTING (state, connecting) {
    state.connecting = connecting
  },
  SET_CURRENT_DOMAIN (state, domain) {
    Vue.set(state.user, 'domain', domain)
  },
  SET_USER_ROLES (state, roles) {
    Vue.set(state.user, 'roles', roles)
  },
  AUTHENTICATED (state, status) {
    if (!status) {
      Vue.set(state, 'user', {})
      storage.removeItem('id_token')
      storage.removeItem('id_user')
      storage.removeItem('ttl_token')
      storage.removeItem('created_token')
      storage.removeItem('main.request.url')
    }
    Vue.set(state.user, 'authenticated', status)
  },
}
const getters = {
  needs_required_datas: (state, getters, rootState, rootGetters) => {
    if (!getters.isAuthenticated) {
      return false
    }
    if (rootGetters['context/get']('settings.allowUserToSetLastname') !== false && !state.user.lastname) {
      return true
    }
    if (rootGetters['context/get']('settings.allowUserToSetFirstname') !== false && !state.user.firstname) {
      return true
    }
    if (rootGetters['context/get']('settings.allowUserToSetBirthday') !== false && !state.user.birthday) {
      return true
    }
    if (rootGetters['context/get']('settings.legal.cgu.requiredByFront') !== false && get(state.user, 'legal.cguAcceptedVersion', false) !== rootGetters['context/get']('settings.legal.cgu').version) {
      return true
    }
    return false
  },
  is_admin: (state, getters) => {
    return getters.hasRole('superadmin') || getters.hasRole('admin')
  },
  is_superadmin: (state, getters) => {
    return getters.hasRole('superadmin')
  },
  hasRole: (state, getters) => {
    return function (role) {
      if (!isArray(get(state, 'user.roles'))) {
        return false
      }
      return state.user.roles.indexOf(role) > -1
    }
  },
  getCurrentUser: (state, getters) => {
    return state.user
  },
  getCurrentDomain: (state, getters) => {
    return state.user.domain
  },
  isConnecting: (state, getters) => {
    return state.connecting
  },
  isAuthenticated: (state, getters) => {
    return state.user.authenticated
  },
  getRoles: (state, getters) => {
    return state.user.roles
  },
  getAllowedCompanies: (state, getters) => {
    const companyUsers = store.filter('CompanyUser', { userId: state.user.id })

    const allowedCompanies = compact(uniq(map(filter(companyUsers, o => {
      return isUndefined(ALLOWED_ROLES) || includes(ALLOWED_ROLES, o.role)
    }), 'companyId')))

    return store.filter('Company', { where: { _id: { in: allowedCompanies } } }, { orderBy: 'name' })
  },
}
const actions = {
  // @info - Envoie une requête à la route 'login/' de l'API avec les credentials entrés dans le formulaire de connexion
  login ({ dispatch, commit }, credentials) {
    storage.setItem('main.request.url', '/')
    commit('AUTHENTICATED', false)
    commit('CONNECTING', true)
    return Vue.http.post(LOGIN_URL(), credentials, vueHttpConfig())
      .then((response) => {
        const { access_token } = response.data
        const data = JSON.parse(Buffer.from(access_token.split('.')[1], 'base64').toString())
        console.log('SUCCESS lors de la connexion')
        // @info - Vérifier les objets retenus en localStorage / Souci de sécurité?
        storage.setItem('id_token', access_token)
        updateAdapter()
        storage.setItem('id_user', data.sub)
        storage.setItem('ttl_token', data.exp - data.iat)
        storage.setItem('created_token', new Date(data.iat * 1000))
        // on purge les notifications
        commit('refreshAlerts')
        // on connect l'utilisateur
        dispatch('currentUser')
        set(store.getAdapter('jsonApi').http.defaults.headers, 'common.Authorization', `Bearer ${access_token}`)
        return ''
      }, (response) => {
        console.log('ERROR lors de la connexion')
        if (response.body) {
        // logout
          commit('AUTHENTICATED', false)
          commit('CONNECTING', false)
          const error = response.body
          console.error(`API ERROR [${error.message}] (${error.statusCode})`)
          commit('addAlert', { icon: true, type: 'error', message: 'Erreur de connexion', persist: false })
          throw response.body.message
        }
      })
  },
  logout ({ commit }) {
    for (const model of Object.keys(store._collections)) {
      if (model !== 'Context') {
        try {
          store.removeAll(model)
        } catch (error) {
          // error
        }
      }
    }
    console.log('[ AUTH ] logout')
    commit('RESET')
    commit('AUTHENTICATED', false)
    commit('CONNECTING', false)
    router.push({ name: 'login' })
  },
  updatePermission ({ commit, dispatch, state, getters }) {
    commit('SET_USER_ROLES', map(state.user.roles, o => { return o.name }))
    if (getters.getCurrentUser.roles.length > 0) {
      commit('SET_CURRENT_DOMAIN', 'grains')
    } else if (getters.getAllowedCompanies.length > 0) {
      commit('SET_CURRENT_DOMAIN', 'client')
    } else {
      commit('SET_CURRENT_DOMAIN', 'public')
    }
    dispatch('currentUserIsLoaded')
  },
  loadUserFromToken ({ commit, dispatch, state }, token) {
    Vue.http.get(`${ME_URL()}?access_token=${token}`, vueHttpConfig()).then(
      (response) => {
        try {
          storage.setItem('id_token', token)
          updateAdapter()
          const body = response.data
          delete body.roles
          const user = store.add('AppUser', body)
          const u = user
          u.token = storage.getItem('id_token')
          u.id = user._id
          storage.setItem('id_user', u._id)
          commit('SET_USER', u)
        } catch (error) {
          console.error(error)
        }
      },
      (error) => {
        dispatch('logout')
        if (error.status === 403) {
          commit('addAlert', { icon: true, type: 'info', message: 'Lien expiré', persist: false })
        } else if (error.status === 401) {
          commit('addAlert', { icon: true, type: 'info', message: 'Lien invalide ou expiré', persist: false })
        } else {
          commit('addAlert', { icon: true, type: 'info', message: 'Vous devez vous reconnecter.', persist: false })
        }
      },
    )
  },
  currentUserIsLoaded () {
  },
  userIsLogged ({ commit }) {
    return commit('AUTHENTICATED', true)
  },
  loadUserPermissions  ({ commit, dispatch, state }, user) {
    user.loadRolesAndPermission().then(user => {
      user.id = user._id
      commit('SET_USER', user)
      dispatch('userIsLogged')
      dispatch('updatePermission')
      commit('CONNECTING', false)
      const follow = storage.getItem('main.request.url')
      if (follow) {
        router.push({ path: follow })
      } else {
        router.push({ path: '/' })
      }
    })
  },
  loadUser ({ commit, dispatch, state }) {
    let follow = storage.getItem('main.request.url')
    if (follow === '/login' || follow === '') {
      follow = '/'
      storage.setItem('main.request.url', '/')
    }
    if (window.location.pathname.startsWith('/reset/')) {
      commit('CONNECTING', false)
      return
    }
    const token = window.localStorage.getItem('id_token')

    axios.get(`${ME_URL()}?access_token=${token}`, { headers: vueHttpConfig() })
      .catch(() => {
        throw new Error('RECONNECT')
      })
      .then(response => {
        if (get(response, 'data._id') === storage.getItem('id_user')) {
          return store.find('AppUser', storage.getItem('id_user'), { force: true })
        } else {
          throw new Error('RECONNECT')
        }
      })
      .then(user => {
        user.id = user._id
        const u = user
        u.token = storage.getItem('id_token')

        dispatch('loadUserPermissions', user)
        return state.user
      })
      .catch(err => {
        console.error(err)

        commit('AUTHENTICATED', false)
        commit('CONNECTING', false)
        if (err === 'RECONNECT') {
          commit('addAlert', { icon: true, type: 'error', message: 'Erreur lors du chargement de votre compte, veuillez recharger la page et réessayer.', persist: false })
        } else {
          commit('addAlert', { icon: true, type: 'info', message: 'Vous devez vous reconnecter.', persist: false })
        }
        return false
      })
  },
  currentUser ({ commit, dispatch, state }) {
    if (!storage.getItem('id_token')) {
      commit('CONNECTING', false)
      return false
    }

    if (!state.user.token || storage.getItem('id_token') !== state.user.token) {
      updateAdapter()
      dispatch('loadUser')
    } else {
      return state.user
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
