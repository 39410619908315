import Configuration from '@/api/configuration.js'
import bowser from 'bowser'
import moment from 'moment'
import { get, padStart } from 'lodash'
import { trailingSlashIt } from '@/helpers/slash.js'

const browser = bowser.getParser(get(window, 'navigator.userAgent', ''))
const compatiblities = {
  chrome: '>60',
  firefox: '>50',
  mobile: {
    safari: '>=10',
  },
}
const browserSatisfaction = browser.satisfies(compatiblities)
const browserUsed = get(browser.parse(), 'parsedResult', {})

window.setInterval(() => {
  const viewport = document.head.querySelector('[name="viewport"]').attributes.content
  const isMobile = screen.availWidth < 768

  const value = isMobile ? 'width=device-width, initial-scale=1, user-scalable=0' : 'width=device-width, initial-scale=1'
  if (viewport.value !== value) {
    viewport.value = value
  }
}, 1000)

const utils = {
  globals: {
    SPACE: 'frontoffice',
    API: trailingSlashIt(Configuration.basePath),
    ENV: process.env.NODE_ENV, // development, staging, production
    EXERCICES: ['multiple-choice', 'combined-choice', 'free-answer', 'drag-n-drop', 'pick-words', 'image-cliquable'],
    LIMITGLOBAL: 50,
    LIMITCOMPETENCY: 20,
    LIMITCATEGORY: 50,
    BROWSER: {
      isDesktop: get(browserUsed, 'platform.type', 'desktop') === 'desktop',
      used: browserUsed,
      compatiblities,
      browserSatisfaction,
      compatibleBrowsers: [
        {
          name: 'Chrome',
          url: 'https://www.google.com/chrome/',
        }, {
          name: 'Firefox',
          url: 'https://www.mozilla.org/fr/firefox/',
        },
      ],
    },
  },
  COLORS: {
    transparent: 'rgba(0, 0, 0, 0)',
    badgePlus: '#00FF00',
    badgeMinus: '#FF0000',
    primary: '#20a0ff',
    success: '#00b550',
    warning: '#ffa800',
    danger: '#ff4949',
    info: '#50bfff',
    admin: '#e66767',
    client: '#20a0ff',
    white: '#ffffff',
    dark: '#333333',
    grey: '#666666',
    competencies: {
      pi: '#FF0000',
    },
    programPack: 'rgb(89,73,250)',
    tertiary: 'rgb(46, 56, 69)',
    colorChart: ['#950AFF', '#E8BB0C', '#0AFF00', '#5949FA', '#0CA7E8', '#5CB2AE', '#FF4C69', '#F700FF', '#00CC1B', '#AB5F00'],
  },
  getFilePath: function (id) {
    return `${Configuration.basePath}files/${id}/download`
  },
  humanizedDuration (time, unit = 's', showSec = false) {
    const duration = moment.duration(Number(time), unit)

    const hours = Math.floor(duration.asHours())
    const hoursString = hours > 0 ? `${hours}h` : null

    const minutes = Math.floor(duration.minutes())
    let minutesString = null
    if (!showSec || minutes > 0) {
      minutesString = hoursString
        ? `${padStart(String(minutes), 2, '0')}min`
        : `${minutes}min`
    }

    let secondesString = null
    if (showSec && !hoursString) {
      const secondes = Math.floor(duration.seconds())
      secondesString = minutesString
        ? `${padStart(String(secondes), 2, '0')}sec`
        : `${secondes}sec`
    }

    return [hoursString, minutesString, secondesString].filter(Boolean).join(' ')
  },
  getTextWidth (text, font) {
    // re-use canvas object for better performance
    const canvas = utils.getTextWidth.canvas || (utils.getTextWidth.canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  },
}

export default utils
