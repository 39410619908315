
import { Record, Schema } from 'js-data'
import store from '@/api'
import Mapper from '../mapper.js'

class AppRoleRecord extends Record {
  get users () {
    const roleUsers = store.getAll('RoleUser', this.id, { index: 'roleId' })
    return roleUsers.map((roleUsers) => {
      return roleUsers.user
    }).filter((user) => user)
  }
}

const AppRoleSchema = new Schema({
  title: 'AppRoleSchemaTitle',
  description: 'Schema for AppRole Records.',
  type: 'object',
  additionalProperties: true,
})

export const AppRole = store.defineMapper('AppRole', {
  mapperClass: Mapper,
  schema: AppRoleSchema,
  endpoint: 'roles',
  relations: {
    hasMany: {
      RoleUser: {
        foreignKey: 'roleId',
        localField: 'role-users',
      },
    },
  },
  recordClass: AppRoleRecord,
})
