/* eslint-disable no-unneeded-ternary */
import utils from '@/helpers/utils.js'
import Vue from 'vue'
import { forOwn } from 'lodash'
//  @info - Imports

const state = () => ({
  page: {
    randomizer: 0,
  },
  layout: {
    background: null,
    tincanIsOpen: false,
  },
})
const mutations = {
  SET_PAGE_RANDOMIZER (state, key) {
    Vue.set(state.page, 'randomizer', key)
  },
  SET_LAYOUT_BACKGROUND (state, id) {
    Vue.set(state.layout, 'background', id)
  },
  TINCAN_IS_OPEN (state, value) {
    Vue.set(state.layout, 'tincanIsOpen', value)
  },
}
const getters = {
  getLayoutTincanIsOpen: (state, getters) => {
    return state.layout.tincanIsOpen
  },
  getPageRandomizer: (state, getters) => {
    return state.page.randomizer || getters.getCurrentUser.id || `${Math.random()}`
  },
  getLayoutBackground: (state, getters) => {
    if (state.layout.background) {
      const url = utils.getFilePath(state.layout.background)
      return `url(${url})`
    } else {
      return false
    }
  },
}
const actions = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  SetPageRandomizer ({ dispatch, commit }, key) {
    commit('SET_PAGE_RANDOMIZER', key)
  },
  SetLayoutBackground ({ dispatch, commit }, id) {
    commit('SET_LAYOUT_BACKGROUND', id)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
