export default {
  methods: {
    setResourceToDisplayInModal (resource) {
      this.$store.commit('SET_RESOURCE', resource)
    },
    removeResourceToDisplayInModal () {
      this.$store.commit('SET_RESOURCE', null)
    },
  },
  computed: {
    getResourceToDisplayInModal () {
      return this.$store.getters.getResourceToDisplayInModal
    },
  },
}
