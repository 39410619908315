import { Schema } from 'js-data'
import store from '@/api'
import Mapper from '../mapper.js'

const RoleShema = new Schema({
  title: 'RoleShemaTitle',
  description: 'Schema for Role Records.',
  type: 'object',
  properties: {
  },
  additionalProperties: true,
})

export const Class = store.defineMapper('Role', {
  mapperClass: Mapper,
  schema: RoleShema,
  endpoint: 'roles',
  relations: {
    hasMany: {
      ClassUser: {
        foreignKey: 'roleId',
        localField: 'classUsers',
      },
    },
  },
})
