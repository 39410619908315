import {
  clone,
  find,
  get,
  includes,
  isArray,
  isEmpty,
  truncate,
  uniq,

} from 'lodash'
import utils from '@/helpers/utils.js'
import moment from 'moment'

export default {
  data () {
    return {
      globals: utils.globals,
    }
  },
  filters: {
    toLowerCase: (s) => s.toLowerCase(),
    round: Math.round,
    date (date, format = 'dddd DD MMM YYYY') {
      return moment(date).format(format)
    },
    fileToReadableString (filename) {
      return truncate(filename.replace(/_/g, ' '), {
        length: 56,
        omission: ' …',
      })
    },
  },
  computed: {
    isDesktop () {
      return !this.isMobile
    },
    isMobile () {
      return get({ xs: true }, this.$mq, false)
    },
    certificationTitle () {
      return `${this.certificationLabel}s`
    },
    certificationLabel () {
      const contextType = this.$store.getters['context/getCertificationType']
      if (contextType === 'attestation') {
        return 'Attestation'
      }
      return 'Certificat'
    },
  },
  methods: {
    formatFileSize (size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return `${(size / 1024 / 1024 / 1024 / 1024).toFixed(2).replace('.', ',')} To`
      } else if (size > 1024 * 1024 * 1024) {
        return `${(size / 1024 / 1024 / 1024).toFixed(2).replace('.', ',')} Go`
      } else if (size > 1024 * 1024) {
        return `${(size / 1024 / 1024).toFixed(2).replace('.', ',')} Mo`
      } else if (size > 1024) {
        return `${(size / 1024).toFixed(2).replace('.', ',')} Ko`
      }
      return `${size.toLocalString()} o`
    },
    fileToReadableString (filename) {
      return truncate(filename.replace(/_/g, ' '), {
        length: 56,
        omission: ' …',
      })
    },
    hexToRgba (hexColor, opacity = 1) {
      let c
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexColor)) {
        c = hexColor.substring(1).split('')
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = `0x${c.join('')}`
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(', ')}, ${opacity})`
      }
      return hexColor
    },
    containsHtmlTags (str) {
      const doc = new window.DOMParser().parseFromString(str, 'text/html')
      return Array.from(doc.body.childNodes).some(node => node.nodeType === 1)
    },
    log: console.log,
    setLayoutBackground (id = false) {
      this.$store.dispatch('SetLayoutBackground', id)
    },
    setOpenedLessonInStorage (lesson) {
      const storage = {
        courseNumber: lesson.id,
        id: lesson.classId,
        sectionNumber: 0,
        sessionNumber: [lesson.id, lesson.classId],
      }
      window.localStorage.setItem('lesson.collapseOpened', JSON.stringify(storage))
    },
    backToHome ({ clear }) {
      const datas = { name: 'Home' }
      if (clear) {
        let storage = window.localStorage.getItem('lesson.collapseOpened')
        if (storage) {
          try {
            storage = JSON.parse(storage)
          } catch (e) {
            window.localStorage.removeItem('lesson.collapseOpened')
            storage = {}
          }
          delete storage.courseNumber
          window.localStorage.setItem('lesson.collapseOpened', JSON.stringify(storage))
        }
      }
      this.$router.push(datas)
    },
    timerConfirm (page, cb) {
      if (page.timeLimit) {
        this.$confirm('L\'exercice que vous allez faire est chronométré.', 'Exercice chronométré',
          {
            confirmButtonText: 'C\'est parti',
            cancelButtonText: 'Peut être plus tard',
            cancelButtonClass: 'maybe-later',
            type: 'chrono',
            customClass: 'chrono',
          }).then(() => {
          cb()
        }).catch(() => {
          this.backToHome({ clear: true })
        })
      } else {
        cb()
      }
    },
    moment: moment,
    shuffleSeed (_array, seed) {
      let seedToString = `${seed}`
      seedToString = seedToString.split('').map(function (c, i) { return c.charCodeAt(0) }).join('')
      const seedLength = seedToString.length
      const seedToNumber = 0 + seedToString
      const array = clone(_array)
      let index = -1
      const size = array.length
      seedToString = `${Math.floor(seedToNumber / Math.pow(10, (seedLength)) * Math.pow(10, size))}`
      while (++index < size) {
        const seedIndex = (0 + seedToString.charAt(index % seedToString.length)) % size
        const value = array[seedIndex]
        array[seedIndex] = array[index]
        array[index] = value
      }
      array.length = size
      return array
    },
    getWindowDimensions () {
      const w = window
      const d = document
      const e = d.documentElement
      const g = d.getElementsByTagName('body')[0]
      const x = w.innerWidth || e.clientWidth || g.clientWidth
      const y = w.innerHeight || e.clientHeight || g.clientHeight
      return { width: x, height: y }
    },
    getFilePath: utils.getFilePath,
    isNotEmptyArray (obj) {
      return isArray(obj) && obj.length > 0
    },
    isEmptyArray (obj) {
      return isArray(obj) && obj.length === 0
    },
    _uniq: uniq,
    _get: get,
    _includes: includes,
    _isEmpty: isEmpty,
    _find: find,
  },
}
