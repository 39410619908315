import { Schema } from 'js-data'
import store from '@/api'
import Mapper from '../mapper.js'

const LibrarySchema = new Schema({
  title: 'LibrarySchemaTitle',
  description: 'Schema for Library Records.',
  type: 'object',
  properties: {
    ownerId: {
      type: 'string',
    },
    ownerType: {
      type: 'string',
    },
    files: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  additionalProperties: true,
})
export const Library = store.defineMapper('Library', {
  mapperClass: Mapper,
  schema: LibrarySchema,
  endpoint: 'libraries',
  relations: {
    hasMany: {
      AppFile: {
        foreignKey: 'libraryId',
        localField: 'files',
      },
    },
  },
})
