import { Schema } from 'js-data'
import store from '@/api'
import Mapper from '../mapper.js'

const CourseTypeSchema = new Schema({
  title: 'CourseTypetitle',
  description: 'Schema for CourseType Records.', // optional
  type: 'object',
  properties: {
  },
  additionalProperties: true,
  required: ['title'],
})

export const CourseType = store.defineMapper('CourseType', {
  mapperClass: Mapper,
  schema: CourseTypeSchema,
  endpoint: 'course-types',
  relations: {
    hasMany: {
      Course: {
        localField: 'course',
        foreignKey: 'typeId',
      },
    },
  },
})
