import { Schema } from 'js-data'
import Mapper from '../mapper.js'
import store from '@/api'
const CourseBlockSchema = new Schema({
  title: 'CourseBlockShemaTitle',
  description: 'Schema for CourseBlock Records.', // optional
  type: 'object',
  properties: {
    type: {
      type: 'string',
      enum: ['exercice', 'slide', 'tincan'],
    },
    categoryId: {
      type: ['string', 'null'],
    },
    competencyId: {
      type: ['string', 'null'],
    },
    attachmentIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  additionalProperties: true,
  required: ['title'],
})

export default store.defineMapper('CourseBlock', {
  mapperClass: Mapper,
  schema: CourseBlockSchema,
  endpoint: 'course-blocks',
  relations: {
    belongsTo: {
      CourseCategory: {
        localField: 'category',
        localKey: 'categoryId',
      },
      CourseCompetency: {
        localField: 'competency',
        localKey: 'competencyId',
      },
    },
    hasMany: {
      CourseTerm: {
        loadAllDatasWhenEditing: false,
        localField: 'terms',
        localKey: 'termIds',
      },
      CourseNode: {
        localField: 'nodes',
        localKey: 'blockId',
      },
    },
  },
})
