/* eslint-disable no-extend-native */
// Array.prototype.find = Array.prototype.find || function(callback) {
if (!Array.prototype.find) {
  // Define as Property otherwise for(x in y) will loop over the function.
  Object.defineProperty(Array.prototype, 'find', {
    value: function (callback) {
      if (this === null) {
        throw new TypeError('Array.prototype.find called on null or undefined')
      } else if (typeof callback !== 'function') {
        throw new TypeError('callback must be a function')
      }
      const list = Object(this)
      // Makes sures is always has an positive integer as length.
      const length = list.length >>> 0
      const thisArg = arguments[1]
      for (let i = 0; i < length; i++) {
        const element = list[i]
        if (callback.call(thisArg, element, i, list)) {
          return element
        }
      }
    },
  })
}

/**
* @license MIT, GPL, do whatever you want
* @requires polyfill: Array.prototype.slice fix {@link https://gist.github.com/brettz9/6093105}
*/
if (!Array.prototype.from) {
  Object.defineProperty(Array.prototype, 'from', {
    value: function (object) {
      return [].slice.call(object)
    },
  })
}
