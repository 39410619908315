
import { Record, Schema } from 'js-data'
import store from '@/api'
import Mapper from '../mapper.js'

class RoleUserRecord extends Record {
}

const RoleUserSchema = new Schema({
  title: 'RoleUserSchemaTitle',
  description: 'Schema for RoleUser Records.',
  type: 'object',
  properties: {
    scopeId: {
      type: ['null', 'string'],
    },
    principalType: {
      type: 'string',
    },
    principalId: {
      type: 'string',
    },
    roleId: {
      type: 'string',
    },
  },
  additionalProperties: true,
})

export const RoleUser = store.defineMapper('RoleUser', {
  mapperClass: Mapper,
  schema: RoleUserSchema,
  endpoint: 'role-users',
  relations: {
    belongsTo: {
      AppRole: {
        localKey: 'roleId',
        localField: 'role',
      },
      AppUser: {
        localKey: 'principalId',
        localField: 'user',
      },
    },
  },
  recordClass: RoleUserRecord,
})
