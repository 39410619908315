export default {
  methods: {
    contactAutoHide () {
      this.$store.commit('CONTACTFORM_AUTOHIDE')
    },
    contactUser (id) {
      this.$store.commit('CONTACTFORM_SET_TO', id)
    },
    contactDocument (ids) {
      this.$store.commit('CONTACTFORM_SET_FILE', ids)
    },
  },
}
