<template lang="pug">
  doctype html
  .pi-uploads-preview(:class="getTheme")
    template(v-if="!_isEmpty(value)")
      template(v-if="hasPrevisu")
        //- AVEC PREVISU
        template(v-if="hasRatio", style="position: relative;")
          //- AVEC RATIO
          .ratio.image(:style="`padding-bottom: ${ratioPadding}%;` + `background-image: url(${getPrevisu})`")
            i.el-icon-pi-close.deleteIcon(v-if="canBeDeleted", @click="delEmit")
            i.el-icon-pi-pencil.modifIcon(v-if="canBeModified", @click="modifEmit")
        template(v-else, style="position: relative;")
          //- SANS RATIO
          .image(style="position: relative;")
            img.preview(:src="value.download")
            i.el-icon-pi-close.deleteIcon(v-if="canBeDeleted", @click="delEmit")
            i.el-icon-pi-pencil.modifIcon(v-if="canBeModified", @click="modifEmit")

      template(v-else)
        //- ICONES
        template(v-if="hasRatio")
          .ratio.bg-colored(:style="`padding-bottom: ${ratioPadding}%;`")
            .pi-uploads-preview-wrapper
              .preview
                i(:class="`el-icon-${value.icon}`")
                p {{ (value.title || value.name) | fileToReadableString }}
                i.el-icon-pi-close.deleteIcon(v-if="canBeDeleted", @click="delEmit")
            i.el-icon-pi-pencil.modifIcon(v-if="canBeModified", @click="modifEmit")
        template(v-else)
          .preview.bg-colored
            i(:class="`el-icon-${value.icon}`")
            p {{ (value.title || value.name) | fileToReadableString }}
            i.el-icon-pi-close.deleteIcon(v-if="canBeDeleted", @click="delEmit")
            i.el-icon-pi-pencil.modifIcon(v-if="canBeModified", @click="modifEmit")

</template>

<script>
import {
  isNumber,
  padStart,
} from 'lodash'
const POSSIBLE_PDF = 10
const FIRST_PDF_INDEX = 1

Math.seed = function (s) {
  s = Math.sin(s) * 10000
  return s - Math.floor(s)
}

function hashCode (string) {
  let hash = 0
  let i
  let chr
  if (string.length === 0) return hash
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0
  }
  return hash
}

export default {
  name: 'pi-uploads-preview',
  components: {},
  filters: {
  },
  mixins: [],
  props: ['value', 'ratio', 'theme', 'canBeDeleted', 'canBeModified', 'icon'],
  data () {
    return {
    }
  },
  computed: {
    isPdf () {
      return this.value.fileType === 'pdf'
    },
    getPrevisu () {
      if (this.isPdf) {
        return `/static/icones/picto_pdf_${padStart(Math.round(Math.seed(hashCode(this.value.download)) * POSSIBLE_PDF + FIRST_PDF_INDEX), 2, '0')}.svg`
      } else {
        return this.value.download
      }
    },
    hasPrevisu () {
      return (this.isPdf || this.value.fileType === 'image') && this.icon !== false
    },
    hasRatio () {
      return isNumber(this.ratio)
    },
    ratioPadding () {
      return 100 * this.ratio
    },
    getTheme () {
      return this.theme || 'light'
    },
  },
  methods: {
    delEmit () {
      this.$emit('delete')
    },
    modifEmit () {
      this.$emit('modif')
    },
  },
}
</script>
