import store from '@/api'
import Vue from 'vue'
import { debounce, forOwn } from 'lodash'

const state = () => ({
  stats: {},
})

const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  SET_USER_SCORE (state, stats) {
    Vue.set(state, 'stats', stats)
  },
}
const getters = {
  getCurrentUserStats: (state, getters) => {
    return state.stats
  },
}
const actions = {
  currentUserIsLoaded ({ dispatch, commit, getters }) {
    dispatch('updateUserScore')
  },
  updateUserScore: debounce(({ dispatch, commit, getters }) => {
    const user = store.get('AppUser', getters.getCurrentUser.id)
    if (user) {
      user.getLastRecord()
        .then(response => {
          commit('SET_USER_SCORE', response)
        })
    }
  }, 200),

}
export default {
  state,
  getters,
  actions,
  mutations,
}
