/* eslint-disable no-unneeded-ternary */
import Vue from 'vue'

export default {
  state: {
    resource: null,
  },
  mutations: {
    SET_RESOURCE (state, resource) {
      Vue.set(state, 'resource', resource)
    },
  },
  getters: {
    getResourceToDisplayInModal: (state, getters) => {
      return state.resource
    },
  },
  actions: {

  },
}
