import { Record, Schema } from 'js-data'
import store from '@/api'
import Mapper from '../mapper.js'

const ProgramCourseSchema = new Schema({
  title: 'ProgramCourseSchemaTitle',
  description: 'Schema for ProgramCourse Records.',
  type: 'object',
  properties: {
    programId: {
      type: 'string',
    },
    courseId: {
      type: 'string',
    },
    order: {
      type: 'integer',
    },
    settings: {
      type: 'object',
    },
  },
})
class ProgramCourseRecord extends Record {
}
export const ProgramCourse = store.defineMapper('ProgramCourse', {
  mapperClass: Mapper,
  schema: ProgramCourseSchema,
  endpoint: 'program-courses',
  relations: {
    belongsTo: {
      Program: {
        loadAllDatasWhenEditing: false,
        localField: 'program',
        localKey: 'programId',
      },
      Course: {
        loadAllDatasWhenEditing: false,
        localField: 'course',
        localKey: 'courseId',
      },
    },
  },
  recordClass: ProgramCourseRecord,
})
