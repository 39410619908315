/* eslint-disable no-unused-vars */
// System
import jsdataPlugin from '@/libs/vuex-jsdata-v3-plugin'
import DataStore from '../api/index.js'
import '@/api/models/Context.js'

// @info - C'est ici qu'on importe les models du Datastorage
import '@/api/models/LogBook.js'
import '@/api/models/AppFile.js'
import '@/api/models/AppRole.js'
import '@/api/models/AppUser.js'
import '@/api/models/Class'
import '@/api/models/ClassProgram'
import '@/api/models/ClassUser'
import '@/api/models/Company'
import '@/api/models/CompanyUser.js'
import '@/api/models/Course.js'
import '@/api/models/CourseBlock.js'
import '@/api/models/CourseCategory.js'
import '@/api/models/CourseCompetency.js'
import '@/api/models/CourseNode.js'
import '@/api/models/CourseTerm.js'
import '@/api/models/CourseType.js'
import '@/api/models/Lesson'
import '@/api/models/LessonPage.js'
import '@/api/models/Library.js'
import '@/api/models/Program.js'
import '@/api/models/ProgramCourse.js'
import '@/api/models/Review.js'
import '@/api/models/Record.js'
import '@/api/models/Role'
import '@/api/models/RoleUser.js'

export default [jsdataPlugin(DataStore)]
