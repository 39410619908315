import Vue from 'vue'
import VueI18n from 'vue-i18n'
import * as locales from './locales/all'
const messages = JSON.parse(JSON.stringify({
  fr: locales.fr,
}))
Vue.use(VueI18n)

const i18n = new VueI18n({
  globalInjection: true,
  locale: 'fr',
  fallbackLocale: 'fr',
  messages,
})

export default i18n
