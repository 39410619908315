import { Mapper } from 'js-data'
import { isArray, isObject } from 'lodash'

class MyMapper extends Mapper {
  createRecord (props, opts) {
    const scope = this
    props || (props = {})
    if (isArray(props)) {
      return props.map(function (_props) {
        return scope.createRecord(_props, opts)
      })
    }
    if (!isObject(props)) {
      throw console.error('#createRecord', 'props', 400, 'array or object', props)
    }
    const RecordCtor = this.recordClass

    return !RecordCtor || props instanceof RecordCtor ? props : new RecordCtor(props, opts)
  }
}

export default MyMapper
