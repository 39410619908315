import { Record, Schema } from 'js-data'
import store from '@/api'
import { addActions } from 'js-data-http'
import Mapper from '../mapper.js'

class LessonPageRecord extends Record {
  getResources () {
    return this.constructor.mapper.getResources(this.id, { params: { lessonId: this.lessonId } }).then(response => {
      if (!response.data || !response.data.length) return null
      const courseBlocks = [...new Set(...response.data.map(lp => lp.block))]
      store.add('CourseBlock', courseBlocks)
      const courseNodes = [...new Set(...response.data.map(lp => lp.node))]
      return store.add('CourseNode', courseNodes)
    })
  }
}

const LessonPageSchema = new Schema({
  title: 'LessonPageSchemaTitle',
  description: 'Schema for LessonPage Records.',
  type: 'object',
  properties: {
  },
  additionalProperties: true,
})

export const LessonPage = store.defineMapper('LessonPage', {
  mapperClass: Mapper,
  schema: LessonPageSchema,
  endpoint: 'pages',
  relations: {
    belongsTo: {
      Lesson: {
        parent: true,
        localKey: 'lessonId',
        localField: 'lesson',
      },
      CourseNode: {
        localKey: 'nodeId',
        localField: 'node',
      },
    },
  },
  recordClass: LessonPageRecord,
})

addActions({
  getResources: {
    pathname: 'resources',
    method: 'GET',
  },
})(LessonPage)
