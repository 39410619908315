import { forOwn, get } from 'lodash'
import api from '@/api'
import configuration from '@/api/configuration.js'
import Vue from 'vue'

const CERTIFICATION_TYPES = [
  'certification',
  'attestation',
]
const state = () => ({
  id: null,
  isGeneric: false,
})

// getters
const getters = {
  isGeneric (state) {
    return state.isGeneric === true
  },
  getCertificationType (state, getters) {
    const certificationType = getters.get('settings.certificationType')
    if (CERTIFICATION_TYPES.includes(certificationType)) {
      return getters.get('settings.certificationType')
    }
    return 'certification'
  },
  getManifestJson (state, getters, rootState, rootGetters) {
    if (!getters.get('hosts.api')) {
      return ''
    }
    return `https://${getters.get('hosts.api')}/contexts/current/manifest.json`
  },
  get (state, getters, rootState, rootGetters) {
    return (path) => {
      return get(rootGetters, ['DSContext', state.id, ...path.split('.')])
    }
  },
}

const actions = {
  useGenericContext ({ commit, getters, dispatch }, value) {
    commit('IS_GENERIC', value)
  },
  setContextId ({ commit, getters, dispatch }, id) {
    commit('IS_GENERIC', false)
    commit('SET_CONTEXT_ID', id)
    document.title = getters.get('name')
    // mise à jour des données dans la configuration.

    if (process.env.VUE_APP_NODE_ENV !== 'production' && process.env.VUE_APP_LOCAL === 'true') {
      // In development, we don't change the remote API, but only the X-Origin or X-Host

      configuration['X-Host'] = getters.get('hosts.api')
      localStorage.setItem('X-Host', getters.get('hosts.api'))
      api.getAdapter('jsonApi').http.defaults.headers.common['X-Host'] = configuration['X-Host']
    } else {
      configuration.basePath = `https://${getters.get('hosts.api')}/`
    }

    // configuration.basePath = trailingSlashIt(process.env.VUE_APP_API_GENERIC)
    api.basePath = configuration.basePath
    api.getAdapter('jsonApi').basePath = configuration.basePath

    dispatch('replaceLabels')
  },
  replaceLabels ({ getters }) {
    Vue.locale('fr').context.credit.title = getters.get('metas.creditLabel')
    Vue.locale('fr').context.credit.addMany = Vue.t('actions.add.manySomething', { something: Vue.tc('context.credit.title') })
    Vue.locale('fr').context.credit.myPlural = `Mes ${Vue.tc('context.credit.title')}`
  },
}

const mutations = {
  IS_GENERIC (state, value) {
    state.isGeneric = value || false
    if (state.isGeneric) {
      window.sessionStorage.setItem('useGenericContext', state.isGeneric)
    } else {
      window.sessionStorage.removeItem('useGenericContext')
    }
  },
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  SET (state, { key, value }) {
    state[key] = value
  },
  SET_CONTEXT_ID (state, id, rootState) {
    state.id = id
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
