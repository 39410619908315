/* eslint-disable no-console */
import bowser from 'bowser'
import { register } from 'register-service-worker'
register('/service-worker.js', {
  registrationOptions: { scope: './' },
  ready (registration) {
    console.log('Service worker is active.')
  },

  registered (registration) {
    console.log('Service worker has been registered.')
  },
  cached (registration) {
    console.log('Content has been cached for offline use.')
  },
  updatefound (registration) {
    console.log('New content is downloading.')
  },
  updated (registration) {
    console.log('New content is available; please refresh.')
  },
  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  },
})

// add to screen For Android
const browser = bowser.getParser(window.navigator.userAgent || '').parsedResult
let deferredPrompt
const isMobile = browser && browser.platform && browser.platform.type === 'mobile'
window.addEventListener('load', function () {
  console.log('load')
  window.addEventListener('beforeinstallprompt', function (event) {
    console.log('beforeinstallprompt')
    if (!isMobile) {
      event.preventDefault()
      return
    }
    deferredPrompt = event
    deferredPrompt.prompt()
  })
})
window.setTimeout(() => {
  const dontShowAddToPhone = window.localStorage.getItem('dontShowAddToPhone')
  if (!dontShowAddToPhone) {
    return null
  } else if (!deferredPrompt && !isMobile) {
    return null
  } else {
    document.addEventListener('click', addToHomeScreen)
  }
}, 5000)

function addToHomeScreen () {
  if (!deferredPrompt) {
    return
  } try {
    deferredPrompt.prompt()
    deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt')
        } else {
          console.log('User dismissed the A2HS prompt')
        }
        deferredPrompt = null
      })
  } catch (e) {
  }
  document.removeEventListener('click', addToHomeScreen)
}
