<template lang="pug">
.pi-video-player
  el-dialog.tincan-dialog.no-modal(:visible.sync="isVisible")
    .video
      vue-plyr(
        ref="plyr"
        :emit="['ready']"
        :options="config"
        @ready="videoIsReady"
      )
        div(data-plyr-provider="youtube" :data-plyr-embed-id="youtubeID")

</template>

<script>

import { isString } from 'lodash'
import utils from '@/helpers/utils.js'

export default {
  name: 'pi-video-player',
  components: {},
  mixins: [],
  data () {
    return {
      player: null,
      showVideo: false,
      videoType: null,
      config: {
        hideYouTubeDOMError: true,
        controls: ['restart', 'play-large', 'play', 'fast-forward', 'rewind', 'progress', 'current-time', 'volume', 'captions', 'pip', 'fullscreen'],
        autoplay: true,
        volume: 1,
        clickToPlay: utils.globals.BROWSER.isDesktop,
        hideControls: utils.globals.BROWSER.isDesktop,
        fullscreen: { enabled: true, fallback: true, iosNative: true },
        youtube: { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1, cc_load_policy: 1 },
        captions: { active: true, language: 'auto', update: true },
      },
    }
  },
  computed: {
    url () {
      return this.$store.state.video.url
    },
    youtubeID () {
      const regexExpression = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?[\w?‌=]*)?/
      if (regexExpression.test(this.url) && isString(this.url.match(regexExpression)[1])) {
        return this.url.match(regexExpression)[1]
      }

      return null
    },
    isVisible: {
      get () {
        return Boolean(this.youtubeID)
      },
      set (value) {
        if (!value) {
          this.$store.commit('VIDEODIALOG_HIDE')
        }
      },
    },
  },
  watch: {
    isVisible (value) {
      if (this.$refs?.plyr?.player) {
        this.player = this.$refs.plyr.player
      }
      if (this.player) {
        if (!value) {
          this.player?.stop()
        } else {
          this.player?.play()
        }
      }
    },
  },
}
</script>
