import store from '@/api'

export default {
  computed: {
    currentUserRecord () {
      this.$store.getters.DSAppUser[this.$store.getters.getCurrentUser.id]
      return store.get('AppUser', this.$store.getters.getCurrentUser.id)
    },
    currentDomain () {
      return this.$store.getters.getCurrentDomain
    },
    getCurrentUser () {
      return this.$store.getters.getCurrentUser
    },
    is_connecting () {
      return this.$store.getters.isConnecting
    },
    is_authenticated () {
      return this.$store.getters.isAuthenticated
    },
    is_admin () {
      return this.$store.getters.is_admin
    },
    is_superadmin () {
      return this.$store.getters.is_superadmin
    },
  },
  watch: {},
  methods: {},
}
