import router from '../router'

const ErrorManager = {
  install (Vue, options) {
    Vue.mixin({
      methods: {
        $routeError (type = '404') {
          router.push(`/${type}`)
        },
      },
    })
  },
}

export default ErrorManager
