import { Schema } from 'js-data'
import Mapper from '../mapper.js'
import store from '@/api'

const CompanySchema = new Schema({
  title: 'CompanySchemaTitle',
  description: 'Schema for Company Records.',
  type: 'object',
  properties: {
    avatarId: {
      type: ['string', 'null'],
    },
  },
  additionalProperties: true,
})

export const Company = store.defineMapper('Company', {
  mapperClass: Mapper,
  schema: CompanySchema,
  endpoint: 'companies',
  relations: {},
})
