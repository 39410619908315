import frLocale from 'element-ui/lib/locale/lang/fr'
import './moment/fr.js'

export default {
  ...frLocale,
  errors: {
    error: 'Erreur',
    UNIQUE_USERNAME: {
      title: 'Ce pseudonyme/identifiant est déjà utilisé',
    },
    UNIQUE_USERNAME_OR_EMAIL: {
      title: 'Cet identifiant ou email est déjà utilisé',
    },
    UNDEFINED: {
      title: 'Une erreur est survenue',
      message: 'Nous n\'avons pas pu identifier l\'erreur que vous rencontrez.',
    },
    TYPE_ERROR: {
      title: 'Fichier ou donnée invalide',
      message: 'Le poids ou le type de la donnée transmise semble invalide',
    },
    INVALID_INPUTS: {
      title: 'Vous avez déjà répondu',
      message: 'Il semble que vous ayez déjà répondu à cette question.',
    },
    INVALID_INVITATION_CODE: {
      title: 'Code d\'invitation incorrect',
      message: 'Le code saisi n\'est pas disponible. Nous vous invitons à vérifiez le code saisi.',
    },
    INVALID_PASSWORD: {
      title: 'Mot de passe incorrect',
      message: 'Le mot de passe doit contenir 6 caractères avec au moins : une majuscule, une minuscule, un chiffre et un caractère spécial',
    },
    LESSON_PAGE_SKIPPED: {
      title: 'Temps écoulé',
      message: 'Le temps alloué pour cette étape vient de s\'écouler',
      button: 'Retour au tableau de bord',
    },
    LESSON_SKIPPED: {
      title: 'Oups…',
      message: 'Le temps est écoulé. Cliquez sur "retour au tableau de bord" pour voir vos résultats.',
      button: 'Retour au tableau de bord',
    },
    BAD_REQUEST: {
      title: 'Les données envoyées sont incorrectes.',
    },
    RETRY_RESTRICT: {
      title: 'Etape déjà réalisée',
      message: 'Vous venez de transmettre des réponses à une étape déjà réalisée. Ce parcours ne permet cette action. Vous allez être redirigé vers votre tableau de bord',
    },
    MODEL_NOT_FOUND: {
      title: 'Impossible de trouver la page suivante',
      message: 'Une erreur est survenue lors du passage au prochain exercice ou du retour au tableau de bord. Si vous êtes dans un aperçue ou une correction depuis l\'administation, merci de fermer la boîte de dialogue avec la croix en haut à doite.',
    },
    PATCH_IN_PROGRESS: {
      title: 'Double envois détecté',
      message: 'Il semble que votre réponse a été envoyé deux fois. Vous allez être redirigé vers votre tableau de bord.',
    },
    Forbidden: {
      title: 'Accès refusé',
      message: 'Vous n\'avez pas les permissions nécessaire pour effectuer cette opération',
    },
  },
  models: {
    Program: {
      title: 'Module | Modules',
    },
    AppFile: {
      title: 'Fichier | Fichiers',
    },
    Context: {
      title: 'Environnement | Environnements',
      undefined: 'un environnement | des environnements',
      defined: 'l\'environnement | les environnements',
      generic: {
        title: 'Générique',
        adj: 'génerique | génériques',
      },
    },
    CourseCompetency: {
      title: 'Compétence | Compétences',
      defined: 'la compétence | les compétences',
      undefined: 'une compétence | des compétences',
      to: 'de la compétence | des compétences',
      this: 'de cette compétence | de ces compétences',
      none: 'Aucune compétence',
      description: 'Les compétences permettent de catégoriser des savoirs qui seront transverses à vos Séquences et thèmes.',
    },
    LogBook: {
      title: 'Journal de bord | Journaux de bord',
      defined: 'le journal de bord | les journaux de bord',
      undefined: 'un journal de bord | des journaux de bord',
    },
  },
  components: {
    feedbacks: {
      default: {
        title: '',
      },
    },
    feeds: {
      default: {
        title: '',
      },
      incorrect: {
        title: 'Incorrect',
      },
      correct: {
        title: 'Correct',
      },
      partialy_correct: {
        title: 'Partiellement correct',
      },
    },
    account: {
      title: 'Mes informations',
    },
    level: {
      myPi: 'Mon niveau Pi',
      detailMathComp: 'Mes compétences',
    },
    dashboard: {
      tabs: {
        levels: {
          name: 'Niveaux',
          title: 'Mon évolution dans les différentes compétences',
        },
        jobs: {
          name: 'Thèmes',
          title: 'Mes badges',
        },
        stats: {
          name: 'Statistiques',
          title: '',
        },
        certificates: {
          name: 'Attestations',
          title: '',
        },
        logbook: {
          name: 'Journal de bord',
          title: '',
        },
      },
    },
    hint: {
      title: 'Indice',
    },
    usertab: {
      myProfile: 'Mon profil',
      myDashboard: 'Mon tableau de bord',
      myCourses: 'Mes parcours',
      legalMentions: 'Mentions légales',
      legalDataProtection: 'Protection des données',
    },
    'free-answer': {
      placeholder: 'Entrez votre réponse',
    },
    'media-internal': {
      openPDF: 'Ouvrir le fichier PDF',
    },
    'competency-xp-earned': {
      title: 'Votre niveau compétence | Vos niveaux compétences',
    },
    'job-xp-earned': {
      title: 'Votre niveau thème',
    },
    'pi-xp-earned': {
      title: 'Votre niveau Pi',
    },
    'parcours-xp': {
      title: 'Votre score de parcours',
    },
  },
  attributes: {
    name: {
      title: 'Nom de famille',
    },
    lastname: {
      title: 'Nom de famille',
    },
    firstname: {
      title: 'Prénom',
    },
    email: {
      title: 'Adresse e-mail',
    },
    birthday: {
      title: 'Date de naissance',
    },
    username: {
      title: 'Pseudonyme / identifiant de connexion',
    },
    password: {
      title: 'Mot de passe',
      new: 'Nouveau mot de passe',
      notChange: 'Ne pas changer de mot de passe',
      forget: 'Mot de passe oublié',
    },
    section: {
      title: 'Section | Sections',
      undefined: 'la section | les sections',
    },
  },
  actions: {
    edit: {
      action: 'Éditer',
      title: 'Édition',
      something: 'l\'édition {something}',
    },
    delete: {
      action: 'Supprimer',
      something: 'Supprimer  {something}',
      title: 'Suppression',
      confirmModel: 'Confirmez-vous la suppression {model}',
      confirmWord: 'SUPPRIMER',
    },
    deleteDefinitely: {
      action: 'Supprimer définitivement',
      something: 'Supprimer définitivement  {something}',
      confirmModel: 'Confirmez-vous la suppression définitive {model}',
    },
    choose: {
      action: 'Choisir',
      title: 'Choix',
      something: 'Choisir {something}',
      youSomething: 'Choisissez {something}',
    },
    registerWithInvitationCode: {
      action: 'Utiliser un code d\'invitation',
      title: 'Créer un compte',
    },
    fullscreen: {
      action: 'Plein écran',
    },
    askAccount: {
      action: 'Demander un compte',
    },
    select: {
      action: 'Associer',
      something: 'Sélectionnez {something}',
    },
    signin: {
      action: 'S\'inscrire',
      message: '{contextName} sera bientôt accessible directement en ligne. Si vous souhaitez dès aujourd\'hui créer un compte, merci de compléter le formulaire en cliquant sur le bouton ci-dessous',
    },
    reset: {
      action: 'Définissez votre nouveau mot de passe',
    },
    validate: {
      action: 'Valider',
    },
    cancel: {
      name: 'Annuler',
      action: 'Annuler',
      title: 'Abandon',
    },
    receiveByMail: {
      action: 'Recevoir par mail',
    },
    receive: {
      action: 'Recevoir',
    },
    write: {
      action: 'Ecrire',
      something: 'Saisissez {something}',
    },
    contact: {
      actions: 'Contacter',
      something: 'Contactez {something}',
    },
    continue: {
      action: 'Continuer',
      still: 'Continuer quand même ?',
    },
    next: {
      action: 'Suivant',
    },
    endModule: {
      action: 'Terminer le module',
    },
    stay: {
      action: 'Rester',
    },
    resume: {
      action: 'Reprendre',
    },
    start: {
      action: 'Commencer',
      something: 'Commencer {something}',
    },
    open: {
      action: 'Ouvrir',
    },
    consult: {
      action: 'Consulter',
    },
    accept: {
      action: 'Accepter',
    },
    close: {
      action: 'Fermer',
    },
    send: {
      action: 'Envoyer',
    },
    login: {
      action: 'S\'authentifier',
    },
    connect: {
      action: 'Se connecter',
      name: 'Connexion',
      with: {
        sso:
          'Me connecter avec mon compte {name}',
      },
    },
    disconnect: {
      action: 'Se déconnecter',
      name: 'Déconnexion',
    },
    record: {
      action: 'Enregistrer',
    },
    change: {
      action: 'Changer',
      password: 'Changer de mot de passe',
    },
    download: {
      action: 'Télécharger',
    },
  },
  headers: {
    LegalMentions: 'Mentions légales',
    LegalDataProtection: 'Protection des données',
    ResetToken: 'Renouvellement de mot de passe',
    reset: 'Renouvellement de mot de passe',
    404: 'Oups …',
    Home: 'Mes parcours',
    // Account: 'Mon espace@Mon profil',
    Account: 'Mon profil',
    login: 'Authentification au portail',
    Dashboard: 'Mon tableau de bord',
  },
  routes: {
    Home: 'Tableau de bord',
    Account: 'Mon compte',
    login: 'Connexion',
    PiRenderCourseNode: 'Aperçu brique',
    PiRenderPage: 'Etape',
    preview: 'Aperçu',
    correction: 'Correction',
    PiCorrectionPage: 'Correction',
    404: 'Page non trouvée',
    403: 'Accès restreint',
    PiRenderPageActions: 'Parcours terminé, prêt pour la suite ?',
    PiRenderPageQuestions: 'Parcours terminé, prêt pour la suite ?',
  },
  roles: {
    TEACHER: {
      title: 'Formateur | Formateurs',
      undefined: 'un formateur | des formateurs',
    },
  },
  help: {
    needHelp: 'Besoin d\'aide ? ',
  },
  lesson: {
    step: {
      title: 'Étape | Étapes',
      minus: 'étape | étapes',
      undefined: 'une étape | des étapes',
      defined: 'l\'étapes | les étapes',
    },
    score: {
      title: 'Score',
      point: 'Point | Points',
      short: 'pts',
    },
  },
}
