import { Schema } from 'js-data'
import store from '@/api'
import Mapper from '../mapper.js'

const ProgramSchema = new Schema({
  title: 'ProgramschemaTitle',
  description: 'Schema for Programs Records.',
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    icon: {
      type: ['string', 'null'],
    },
    public: {
      type: 'boolean',
    },
    settings: {
      type: 'object',
    },
    tags: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    state: {
      type: 'string',
      enum: ['DRAFT', 'PUBLISHED', 'CLOSED'],
    },
  },
  required: ['name', 'state'],
})
export const Program = store.defineMapper('Program', {
  mapperClass: Mapper,
  schema: ProgramSchema,
  endpoint: 'programs',
  relations: {
    hasMany: {
      ProgramCourse: {
        localField: 'programCourses',
        foreignKey: 'programId',
      },
      Class: {
        localField: 'classes',
        foreignKey: 'programId',
      },
      ClassProgram: {
        localField: 'classPrograms',
        foreignKey: 'programId',
      },
    },
  },
})
