import { Record, Schema } from 'js-data'
import Mapper from '../mapper.js'
import { addActions } from 'js-data-http'
import api from '@/api'
import { isString, map } from 'lodash'
import utils from '@/helpers/utils.js'

function textToHtml (txt) {
  if (isString(txt)) {
    return txt.replace(/[\r\n]/g, '<br />')
  }
  return txt
}
class AppUserRecord extends Record {
  get id () {
    return this._id
  }

  set id (val) {
    this._id = val
  }

  get displayName () {
    if (this.firstname && this.lastname) {
      return `${this.firstname} ${this.lastname}`
    } else if (this.username) {
      return this.username
    } else if (this.firstname || this.lastname) {
      return this.firstname ? this.firstname : this.lastname
    } else {
      return this.email
    }
  }

  get tincanIdentifier () {
    return this.id
  }

  get avatarImage () {
    return this.avatarId ? utils.getFilePath(this.avatarId) : false
  }

  loadCertifications () {
    return api.findAll('Lesson', {
      filter: {
        userId: this.id,
        certificationRef: { $ne: null },
      },
      populate: [{
        path: 'class',
        populate: { path: 'classPrograms' },
      },
      {
        path: 'course',
      }],
      options: {
        sort: '-startedAt',
      },
    }, {
      force: true,
    })
  }

  loadRolesAndPermission () {
    return api.findAll('RoleUser', { filter: { principalId: this.id }, populate: { path: 'role' } }, { force: true })
      .catch(e => {
        console.error(e)
      })
      .then(roleUsers => {
        return api.findAll('CompanyUser', { filter: { userId: this.id }, populate: { path: 'company' } }, { force: true })
      }).catch(e => {
        console.error(e)
      }).then(CompanyUsers => {
        return this
      })
  }

  loadRoles () {
    // Chargement des ROLES ADMIN
    return api.findAll('RoleUser', { filter: { principalId: this.id } })
      .then(roleUsers => {
        const roles = map(roleUsers, o => { return o.roleId })
        if (roles) {
          return api.findAll('AppRole', { filter: { _id: { $in: map(roleUsers, o => { return o.roleId }) } } })
        }
        return []
      })
  }

  get roles () {
    const roleUsers = api.getAll('RoleUser', this.id, { index: 'principalId' })
    return roleUsers.map((roleUsers) => {
      return roleUsers.role
    }).filter((role) => role)
  }

  stats (global, competency, category) {
    console.error('Deprecated stats() Method')
    return false
  }

  getLastRecord () {
    const params = {
      filter: {
        userId: this.id,
      },
      options: {
        sort: '-date',
        limit: 1,
      },
    }
    return api.findAll('Record', params, { force: true }).then(records => {
      return records[0]
    })
  }

  getLastRecordBefore (date) {
    const params = {
      filter: {
        userId: this.id,
        date: { $lt: date.add(-1, 'day').toISOString() },
      },
      options: {
        sort: '-date',
        limit: 1,
      },
    }
    return api.findAll('Record', params, { force: true }).then(records => {
      return records[0]
    })
  }

  statsTo (global, competency, category, to) {
    console.error('Deprecated statsTo() Method')
    return false
  }

  contact (message) {
    message = textToHtml(message)
    return this.constructor.mapper.contact(this.id, { data: { message } })
  }

  notifyFiles (message, fileIds) {
    return this.constructor.mapper.notifyFiles(this.id, { data: { message, fileIds } })
  }
}

const AppUserSchema = new Schema({
  title: 'AppUserSchemaTitle',
  description: 'AppUser\'s Schema',
  type: 'object',
  properties: {
    username: {
      type: ['string', 'null'],
    },
    firstname: {
      type: 'string',
    },
    lastname: {
      type: 'string',
    },
    avatarId: {
      type: ['string', 'null'],
    },
    email: {
      type: 'string',
    },
    birthday: {
      type: ['string', 'null'],
    },
    legal: {
      type: 'object',
      properties: {
        cguAcceptedVersion: {
          type: 'string',
        },
        cguAcceptedAt: {
          type: 'string',
        },
      },
    },
    supportGroups: {
      track: false,
      type: 'array',
      items: {
        type: 'string',
      },
    },
    scoring: {
      track: false,
      type: 'object',
      additionalProperties: true,
    },
    contributionLevel: {
      track: false,
    },
  },
  additionalProperties: true,
  required: ['email'],
})

export const AppUser = api.defineMapper('AppUser', {
  mapperClass: Mapper,
  schema: AppUserSchema,
  endpoint: 'users',
  relations: {
    hasMany: {
      CompanyUser: {
        localField: 'companyUsers',
        foreignKey: 'userId',
      },
      ClassUser: {
        localField: 'classUsers',
        foreignKey: 'userId',
      },
      RoleUser: {
        foreignKey: 'principalId',
        localField: 'role-users',
      },
      Role: {
        foreignKey: 'principalId',
        localField: 'roles',
      },
      Lesson: {
        foreignKey: 'userId',
        localField: 'lessons',
      },
      Record: {
        foreignKey: 'userId',
        localField: 'PiScoreRecords',
      },
    },
  },
  recordClass: AppUserRecord,
})

const transformUrlForContactForms = function (request) {
  // http://sub.domaine.ext/folder/folder/ON_SUPPRIME_ICI
  request.url = request.url.replace(/\/[^/]*\/?$/, '')
  return request
}
addActions({
  me: {
    pathname: 'me',
    method: 'GET',
  },
  contact: {
    endpoint: 'users/contact',
    request: transformUrlForContactForms,
    method: 'POST',
  },
  notifyFiles: {
    endpoint: 'users/contact',
    request: transformUrlForContactForms,
    method: 'POST',
  },
  records: {
    endpoint: 'records',
    request: transformUrlForContactForms,
    method: 'GET',
  },
})(AppUser)
