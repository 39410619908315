import { forOwn } from 'lodash'

export const TRACKS = {
  MASTER: 'main',
  TMP: 'temporary',
}

const state = () => ({
  track: TRACKS.MASTER, // ou temporary
  master_url: null,
  master_name: null,
  temporary_url: null,
  temporary_name: null,
})

// getters
const getters = {
  mainSoundPlayerTrack (state) {
    return state.track
  },
  mainSoundPlayerSound (state, getters) {
    if (getters.mainSoundPlayerTrack === TRACKS.MASTER) {
      return {
        url: state.master_url,
        name: state.master_name,
        track: state.track,
      }
    }
    return {
      url: state.temporary_url,
      name: state.temporary_name,
      track: state.track,
    }
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  MAINSOUNDPLAYER_SET_TMP_SOUND (state, { name = null, url = null }) {
    state.temporary_url = url
    state.temporary_name = name
    if (url) {
      state.track = TRACKS.TMP
    } else {
      state.track = TRACKS.MASTER
    }
  },
  MAINSOUNDPLAYER_SET_MASTER_SOUND (state, { name = null, url = null }) {
    state.master_url = url
    state.master_name = name
    state.track = TRACKS.MASTER
  },

  MAINSOUNDPLAYER_SET_MASTER_TRACK (state) {
    state.track = TRACKS.MASTER
  },
  MAINSOUNDPLAYER_HIDE_ALL (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
