// System
import Vue from 'vue'
import Vuex from 'vuex'

// Imports
import * as defaultActions from './defaultActions'
import * as defaultGetters from './defaultGetters'
import plugins from './plugins'

// Modules
import Authentification from './modules/authentification'
import Globals from './modules/globals'
import NotificationManager from './modules/notificationManager'
import course from './modules/course'
import contact from './modules/contact'
import optionsInterface from './modules/optionsInterface'
import videoplayer from './modules/videoplayer'
import dashboard from './modules/dashboard'
import userScore from './modules/userScore'
import requiredDatas from './modules/requiredDatas'
import mainSoundPlayer from './modules/mainSoundPlayer'

import resources from './modules/resources'
import context from './modules/context'
Vue.use(Vuex)

const store = new Vuex.Store({
  defaultActions,
  defaultGetters,
  plugins,
  modules: {
    course,
    resources,
    contact,
    context,
    Globals,
    Authentification,
    NotificationManager,
    dashboard,
    optionsInterface,
    mainSoundPlayer,
    userScore,
    requiredDatas,
    video: videoplayer,
  },
  strict: false,
})

export default store
